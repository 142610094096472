import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import StyledFooter from '../../../../../../components/Modal/StyledFooter.js';

const ButtonWrapper = styled.div`
  display         : flex;
  height          : 100%;
  justify-content : space-between;
`;

const Footer = ({ onSave, onCancel, isproceed = false, isAllApprove = false }) => (
  <StyledFooter>
    <ButtonWrapper>
      <Button
        color="secondary"
        style={{ padding: '0 15px' }}
        onClick={onCancel}>
        CANCEL
      </Button>
      {
        isproceed ?
          <Button
            color="secondary"
            onClick={onSave}
          >
            PROCEED
          </Button>
          :
          isAllApprove ?
          <Button
            color="secondary"
            onClick={onSave}
          >
          Approve
          </Button>
          :
          <Button
            color="secondary"
            onClick={onSave}
          >
            SAVE
          </Button>
      }
    </ButtonWrapper>
  </StyledFooter>
);

Footer.propTypes = {
  withoutCancel: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Footer.defaultProps = {
  withoutCancel: false,
};

export default Footer;
