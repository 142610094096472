import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {
  Checkbox,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import SortableTableByState from '../../../components/SortableTableByState';
import SvrFeedbackModal from './review/modals/SvrFeedbackModal';
import ChangesOnSvrModal from './../../modals/ChangesOnSvrModal';
import ImpossibleEditSvrsModal from './../../modals/ImpossibleEditSvrsModal';
import { maybeDecorateColumn } from '../../../utils/tableColumns';
import Modal from '../../../components/Modal/Modal';
import theme from '../../../styles/theme';
import { axiosClient } from '../../../store';

const HEIGHT = 25;
const SVR_RESPONSE_APPROVED_STATUS = 3;
const ADMINISTRATOR_ROLE_ID = 1;

const DeleteIcon = styled(Delete)`
  margin-right : 20px;
  cursor       : pointer;
`;

const Footer = styled.div`
  display         : flex;
  height          : ${HEIGHT + 5}px;
  background      : #f8f8f8;
  justify-content : space-between;
  font-size       : 15px;
  align-items     : flex-end;
  padding-bottom  : 7px;
  & > div {
    color        : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598'};
    cursor       : pointer;
    margin-left  : 30px;
    margin-right : 30px;

    div {
      margin-right: 20px;
    }
  }
`;
const StyledTableRow = styled(TableRow)`
  border-top : 1px solid #C9CACB;
  height     : 30px !important;
`;

const isSortedBy = (k, orderBy) => orderBy === k;
const maybeSortBy = (k, orderBy, order) => isSortedBy(k, orderBy) ? order : false;



class EnhancedTableHead extends Component {
  createSortHandler = (pathStr, type) => event => {
    this.props.onRequestSort(event, pathStr, type);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      tableColumns,
      checkin
    } = this.props;

    return (
      <TableHead>
        <StyledTableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              style={{ height: `${HEIGHT}px` }}
            />
          </TableCell>
          {
            R.map(({ pathStr, label, type, hideColumn }) => {
              return( label === 'Door Latitude'
                || label === 'Door Longitude'
                || label === 'Checkin Latitude'
                || label === 'Checkin Longitude'
                || label === 'Device OS'
                || label === 'Device OS Version'
                || label === 'Device Model'
                || label === 'App Version') ||
                 ((!checkin && (hideColumn || label === 'Project Name')) || (checkin && (hideColumn || label === 'Project Name'
              || label === 'Review State'
              || label === '(Re)submitted Date'
              || label === 'Approved Date'
              || label === 'Approved by'                                                                                                       
              || label === 'Location Territory'
              || label === 'Department Type'
              || label === 'Work Classification'
              || label === 'TD'
              || label === 'PD'                                                                                                       
              || label === 'Checkin Status'
              || label === 'Checkout Status'
              //|| label === 'Hidden From Client'
              || label === 'Authorized by'
              || label === 'Event ID'
              || label === 'Form Canonical Key'
              || label === 'Door Latitude'
              || label === 'Door Longitude'                                                                                                       
              || label === 'Checkin Latitude'
              || label === 'Checkin Longitude'
              || label === 'Call Out'
              || label === 'Device OS'
              || label === 'Device OS Version'
              || label === 'Device Model'  
              || label === 'App Version'                                                                                                         
               ))) 
                ? null
                : <TableCell
                  key={pathStr}
                  numeric={false}
                  padding="dense"
                  type="head"
                  style={ checkin ? { whiteSpace: 'nowrap', flexDirection: 'row', width:'50px' } : { whiteSpace: 'nowrap', flexDirection: 'row' }}
                  sortDirection={maybeSortBy(pathStr, orderBy, order)}
                >
                  <Tooltip
                    title="Sort"
                    placement="bottom-end"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={isSortedBy(pathStr, orderBy)}
                      direction={order}
                      onClick={this.createSortHandler(pathStr, type)}
                    >
                      {label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>;
            })(tableColumns)
          }

        </StyledTableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    minHeight: '53px',
    padding: '0px 8px 25px 15px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: '0 0 auto'
  },
  headerIcon: {
    textAlign: 'right',
    width: '100%'
  }
});

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    title,
    description,
    onFilterByStatus,
    savedView,
    deleteSavedView
  } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <div>
            <Typography variant="title">
              {title || 'SVR Responses'}
            </Typography>
            {
              description && (
                <Typography color="inherit" variant="subheading">
                  {description}
                </Typography>
              )
            }
          </div>
        )}
      </div>
      <div className={classes.headerIcon}>
        <SortableTableByState onFilterByStatus={onFilterByStatus} />
        {
          !R.isEmpty(savedView) && (
            <Tooltip
              title="Delete this saved view"
              placement="left-start"
              enterDelay={300}
            >
              <DeleteIcon onClick={() => {
                deleteSavedView(savedView);
              }} />
            </Tooltip>
          )
        }
      </div>

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  setShowSearchFilter: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  deleteSavedView: PropTypes.func,
  savedView: PropTypes.object
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width: '96%',
    marginTop: '80px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '80px'
  },
  tableWrapper: {
    overflowX: 'auto',
  }
});

/**
 * Properties in JS use camelCaseNames
 * @param {String} str
 * @returns {String}
 */
const delimiterAndNextLetter = /_(.)/g;
const alphaNumeric = /[a-z0-9]/gi;
const uppercaseAlphaNumeric = R.compose(R.toUpper, R.head, R.match(alphaNumeric));
const transformFromColumn = R.replace(delimiterAndNextLetter, uppercaseAlphaNumeric);

class EnhancedTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'desc',
      orderBy: 'id',
      sortType: 'string',
      selected: [],
      page: 0,
      rowsPerPage: props.rowsPerPage,
      showModal: false,
      modalTitle: null,
      changeApprove: null,
      rowsPerPageOptions: props.rowsPerPageOptions,
      isImpossibleEditSvrsModal: false,
      currentUserSvrResponses: [],
      filterByStatus: 1,
      showErrorModal: false,
      errorModalTitle: '',
      feedbackMessage: '',
      showModalFeedback: null,
      saveFeedback: null
    };
  }

  getStatusCode = label => {
    return R.compose(
      R.partialRight(parseInt, [10]),
      R.head,
      R.find(R.compose(R.equals(label), R.last)),
      R.toPairs
    )(window.WINSTON.statuses.storeVisit);
  };

  getReviewState = label => {
    return R.compose(
      R.partialRight(parseInt, [10]),
      R.head,
      R.find(R.compose(R.equals(label), R.last)),
      R.toPairs
    )(window.WINSTON.statuses.reviewState);
  };

  deleteSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Deleted'));
  };

  resetSvrResponses = () => {
    this.resetSvrResponses();
  };

  activeSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Active'));
  };

  archiveSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Archived'));
  };

  dStoreVistFilter(data, cdata) {
    return R.filter(X => X.eventId === cdata)(data);
  }

  approveSvrResponses = () => {
    const { onUpdate, data, namespace } = this.props;

    if (this.state.selected.length > 0) {
      const changeApp = async () => {
        for (var cnt = 0; cnt < this.state.selected.length; cnt++) {
          const dataState = this.dStoreVistFilter(data, this.state.selected[cnt]);   
          if (dataState.length > 0) {
            if (!R.isNil(dataState[0].metaReviewState)) {
              if (dataState[0].metaReviewState === 1) {
                const storeVistid = dataState[0].id;
                const resp = await axiosClient.get(`/api/v2/storeVisit/id/${storeVistid}`, {
                  headers: {
                    Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
                  }
                });

                if (!R.isNil(resp.data.data)) {
                  const updatedMetaJson = R.assoc('reviewState', SVR_RESPONSE_APPROVED_STATUS, resp.data.data.metaJson);
                  const fData = {
                    visitedQuestions: R.pathOr([], ['formState', 'visitedQuestions'], resp.data.data),
                    feedback: {
                      visitedQuestions: R.pathOr([], ['formState', 'feedback', 'visitedQuestions'], resp.data.data),
                      questions: R.pathOr({}, ['formState', 'feedback', 'questions'], resp.data.data),
                      message: this.state.feedbackMessage
                    }
                  };

                  onUpdate(namespace, storeVistid, { metaJson: JSON.stringify(updatedMetaJson), formState: JSON.stringify(fData) });
                }
              }
            }
          }
        }
        this.modalCloseFeedback();
        this.setState({ selected: [] });
      };

      this.setState({
        showModalFeedback: true,
        modalTitle: null,
        saveFeedback: changeApp
      });
    }
  };

  modalClose = () => {
    this.setState({
      showModal: false,
      modalTitle: null,
      changeApprove: null,
      isImpossibleEditSvrsModal: false,
      currentUserSvrResponses: []
    });
  };

  modalCloseFeedback = () => {
    this.setState({
      showModalFeedback: false,
      modalTitle: null,
      saveFeedback: null
    });
  };

  updateSvrResponses = status => {
    const { onUpdate, data, namespace } = this.props;

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(svrResponse => {
          data.forEach(item => {
            if (R.equals(svrResponse, item.eventId)) {
              if (!R.equals(item.status, status)) {
                onUpdate(namespace, item.id, { eventId: item.eventId, status: parseInt(status, 10) });
              }
            }
          });
        });
        this.modalClose();
        this.setState({ selected: [] });
      };

      this.setState({
        showModal: true,
        modalTitle: null,
        changeApprove: change
      });
    }
  };

  resetSvrResponses = () => {
    const { onReset, data, namespace } = this.props;

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(svrResponse => {
          data.forEach(item => {
            if (R.equals(svrResponse, item.eventId)) {
              if (R.propEq('svrType', 1, item)) {
                this.setState({
                  showErrorModal: true,
                  errorModalTitle: "MultiDay SVRs can’t be reset."
                });
              }
              else {
                onReset(namespace, item.id);
              }
            }
          });
        });
        this.modalClose();
        this.setState({ selected: [] });
      };

      this.setState({
        showModal: true,
        modalTitle: 'Are you sure you want to erase the check in and check out times for the selected visit(s)?',
        changeApprove: change
      });
    }
  };

  componentDidUpdate(prevProps) {
    const transformedDataIsDifferent = !R.equals(R.pluck('timestamp', prevProps.transformedData), R.pluck('timestamp', this.props.transformedData));
    const filtersAreDifferent = !R.equals(prevProps.filterList, this.props.filterList);
    const dataIsDifferent = !R.equals(R.pluck('timestamp', prevProps.data), R.pluck('timestamp', this.props.data));

    if (transformedDataIsDifferent || filtersAreDifferent || dataIsDifferent) {

      const order = this.state.order || 'asc',
        orderBy = this.state.orderBy || 'id',
        sortType = this.state.sortType || 'string',
        filterList = this.props.filterList || {};

      this.props.transformData(orderBy, order, sortType, filterList, this.props.data, this.props.namespace);
    }

  }

  handleRequestSort = (event, pathStr, sortType) => {
    const orderBy = pathStr || '';

    let order = 'desc';

    if (this.state.orderBy === pathStr && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, sortType });

    this.props.transformData(orderBy, order, sortType, this.props.filterList, this.props.data, this.props.namespace);
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: R.pluck('eventId', this.props.transformedData) });
      this.props.handleSelectData(R.pluck('eventId', this.props.transformedData));
      return;
    }
    this.setState({ selected: [] });
    this.props.handleSelectData([]);
  };

  handleClick = (event, eventId) => {
    event.stopPropagation();

    const { selected } = this.state;
    const selectedIndex = selected.indexOf(eventId);
    const firstItemIndex = 0;
    const lastItemIndex = selected.length - 1;

    const newSelected = R.defaultTo(
      [],
      R.cond([
        [R.equals(-1), () => [].concat(selected, eventId)],
        [R.equals(firstItemIndex), () => [].concat(selected.slice(1))],
        [R.equals(lastItemIndex), () => [].concat(selected.slice(0, -1))],
        [R.lt(0), () => [].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))],
      ])(selectedIndex)
    );

    this.setState({ selected: newSelected });
    this.props.handleSelectData(newSelected);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleFeedbackChange = event => {
    this.setState({ feedbackMessage: event.target.value });
  };

  isSelected = eventId => R.contains(eventId, this.state.selected);

  onFilterByStatus = filterByStatus => {
    this.setState({ filterByStatus });
  };

  maybeAddMailTo = (isClientUser, path, email, manageremail, text) => (R.contains("mcFullName", path) || R.contains("zone", path)) && email && !isClientUser && text
    ? <a href={`mailto:${R.contains("mcFullName", path) ? email : manageremail}`} onClick={event => event.stopPropagation()}>
      {text}
    </a>
    : text;

  render() {
    const {
      classes,
      goToReviewPage,
      tableColumns,
      transformedData,
      setShowSearchFilter,
      showSearchFilter,
      title,
      description,
      savedView,
      deleteSavedView,
      isClientUser,
      userRole,
      checkin
    } = this.props;

    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      showModal,
      modalTitle,
      changeApprove,
      rowsPerPageOptions,
      filterByStatus,
      isImpossibleEditSvrsModal,
      currentUserSvrResponses,
      showErrorModal,
      errorModalTitle,
      showModalFeedback,
      feedbackMessage,
      saveFeedback
    } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, transformedData.length - page * rowsPerPage);
    const Regionalzonedata = JSON.parse(window.localStorage.getItem('Regionalslist'));

    const filteredByStatusData = () => {
      if (filterByStatus) {
        return transformedData.filter(d => d.status === filterByStatus).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }

      return transformedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedIds={selected}
          setShowSearchFilter={setShowSearchFilter}
          showSearchFilter={showSearchFilter}
          title={title}
          description={description}
          onFilterByStatus={this.onFilterByStatus}
          savedView={savedView}
          deleteSavedView={deleteSavedView}
        />
        <div className={classes.tableWrapper}>
        {showModalFeedback && (
            <SvrFeedbackModal
              feedbackMessage={feedbackMessage}
              onChange={this.handleFeedbackChange}
              onCancel={this.modalCloseFeedback}
              onSave={saveFeedback}
              isAllApprove={true}
            />
          )}
          {showModal &&
            <ChangesOnSvrModal
              onApprove={changeApprove}
              onCancel={this.modalClose}
              items={selected.length}
              title={modalTitle}
            />
          }
          {showErrorModal &&
            <Modal
              withoutCancel
              onApprove={() => this.setState({ showErrorModal: false, errorModalTitle: '' })}>
              {errorModalTitle}
            </Modal>
          }
          {isImpossibleEditSvrsModal &&
            <ImpossibleEditSvrsModal
              onApprove={this.modalClose}
              currentUserSvrResponses={currentUserSvrResponses}
              withoutCancel
            />
          }
          <Table style={checkin?{width : '30%'} : {width : '100%'}}>
            <EnhancedTableHead
              tableColumns={tableColumns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={transformedData.length}
              checkin={checkin}
            />
            <TableBody>
              {filteredByStatusData()
                .map(storeVisit => {
                  const isSelected = this.isSelected(storeVisit.eventId);
                  const isPendingSubmission = storeVisit.metaReviewState === 0;
                  const maybeDisabledStyle = isPendingSubmission ? { cursor: 'default', backgroundColor: '#fbfbfb' } : {};

                  const responsezonefilter = R.filter(R.propEq('zone', storeVisit.metaZone))(Regionalzonedata.data.data) || [];
                  var contactEmail = '';

                  if (R.length(responsezonefilter) > 0) {
                    contactEmail = responsezonefilter["0"].contactemail;
                  }
                  else {
                    contactEmail = '';
                  }

                  return (
                    <TableRow
                      hover
                      onClick={() => goToReviewPage(storeVisit.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={storeVisit.eventId}
                      selected={isSelected}
                      style={{ height: `${HEIGHT}px`, cursor: 'pointer', whiteSpace: 'nowrap', ...maybeDisabledStyle }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{ height: `${HEIGHT}px` }}
                          checked={isSelected}
                          onClick={event => this.handleClick(event, storeVisit.eventId)}
                        />
                      </TableCell>
                      {
                        R.map(({ path, decorator, hideColumn, column }) => {
                          const inferPathOrColumn = () => {
                            if (R.has(path, storeVisit)) {
                              return R.path(path, storeVisit)
                            }

                            const transformedColumn = transformFromColumn(column)
                            return R.prop(transformedColumn, storeVisit)
                          };
                          return (column === 'expected_checkin_latitude'
                            || column === 'expected_checkin_longitude'
                            || column === 'actual_checkin_latitude'
                            || column === 'actual_checkin_longitude'
                            || column === 'device_os'
                            || column === 'device_os_version'
                            || column === 'device_model'
                            || column === 'device_app_version' ) ||
                            ((!checkin && (hideColumn || column === 'meta_project_name')) || (checkin &&  (hideColumn 
                            || column === 'meta_project_name' 
                            || column === 'meta_review_state'
                            || column === 'last_submission_date'
                            || column === 'meta_approved_date'
                            || column === 'meta_reviewed_by'
                            || column === 'meta_territory'
                            || column === 'meta_department_type'                                                                                            
                            || column === 'meta_talent_type'
                            || column === 'meta_talent_division' 
                            || column === 'meta_project_division'
                            || column === 'checkin_status' 
                            || column === 'checkout_status'
                            || column === 'privacy'
                            || column === 'approved_by'
                            || column === 'event_id'
                            || column === 'form_canonical_key' 
                            || column === 'expected_checkin_latitude'
                            || column === 'expected_checkin_longitude' 
                            || column === 'actual_checkin_latitude'
                            || column === 'actual_checkin_longitude'
                            || column === 'device_os'
                            || column === 'device_os_version' 
                            || column === 'device_model'
                            || column === 'device_app_version'                                                                                             
                            )))                        
                            ? null
                            : <TableCell
                              key={path}
                              padding="dense"
                              numeric={false}
                              type="body"
                              style={ checkin? { backgroundColor: R.prop('reviewStateColor', storeVisit) , width:'50px' } : { backgroundColor: R.prop('reviewStateColor', storeVisit) }}
                            >
                              {
                                this.maybeAddMailTo(
                                  isClientUser,
                                  path,
                                  R.prop('metaMcEmail', storeVisit),
                                  contactEmail,
                                  maybeDecorateColumn(decorator)(inferPathOrColumn())
                                )
                              }
                            </TableCell>;
                        })(tableColumns)
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: `${HEIGHT * (emptyRows > 5 ? 5 : emptyRows)}px` }}>
                  <TableCell colSpan={tableColumns.length + 1} style={{ height: `${HEIGHT}px` }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={1}
                  count={transformedData.length}
                  style={{ height: `${HEIGHT}px` }}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        { 
          userRole === ADMINISTRATOR_ROLE_ID ? (
            <Footer isSelected={selected.length > 0}>
             <div style={{ display: 'flex' }}>
                <div style={{ color: theme.palette.pblack.black }} onClick={this.archiveSvrResponses}>ARCHIVE</div>
                <div style={{ color: theme.palette.pblack.black }} onClick={this.deleteSvrResponses}>DELETE</div>
                <Tooltip
                  title="After resetting visits here, you can update them in BOS to be pulled down on the next sync."
                  placement="top-start"
                  enterDelay={300}
                >
                  <div style={{ color: theme.palette.pblack.black }} onClick={this.resetSvrResponses}>RESET</div>
                </Tooltip>
                <div style={{ color: theme.palette.pblack.black }} onClick={this.activeSvrResponses}>ACTIVATE</div>
              </div>
              {/* <div style={{ color: theme.palette.pblack.black }} onClick={this.approveSvrResponses}>APPROVE</div> */}
            </Footer>
          ) : null
        }
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  data: PropTypes.array,
  transformData: PropTypes.func.isRequired,
  transformedData: PropTypes.array,
  transformedDataDict: PropTypes.object,
  tableColumns: PropTypes.array,
  rowsPerPage: PropTypes.number,
  classes: PropTypes.object.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  setShowSearchFilter: PropTypes.func,
  deleteSavedView: PropTypes.func,
  savedView: PropTypes.object,
  isClientUser: PropTypes.bool,
  userRole: PropTypes.number,
  checkin: PropTypes.bool
};

EnhancedTable.defaultProps = {
  data: [],
  transformedData: [],
  transformedDataDict: {},
  tableColumns: [],
  rowsPerPage: 50,
  filterList: {},
  showSearchFilter: false,
  rowsPerPageOptions: [25, 50, 100, 250],
  checkin: false
};

export default withStyles(styles)(EnhancedTable);
