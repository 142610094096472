import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import * as R from 'ramda';

import {
  compose,
  withStateHandlers
} from 'recompose';

import { MenuItem, Select } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import calculateDuration from './utils/calculateDuration';
import checkDelay from './utils/checkDelay';
import toHours from './utils/toHours';

import {
  checkInLate,
  checkOutLate
} from './utils/checkLate';

import { svrTypes } from '../../../../config/dataConfig';

const { MULTI_DAY_SVR, NORMAL_SVR } = svrTypes;

const SVR_REVIEW_STATES = ['Pending Submission', 'Pending Review', 'In Review', 'Approved', 'Rejected', 'Resubmitted'];

const svrHasSubmissions = svr => !!R.pathOr([], ['submissions'], svr).length;


const SVR_RESPONSE_STATUSES = [
  { name: 'Deleted', value: 0 },
  { name: 'Active', value: 1 },
  { name: 'Archived', value: 2 },
  { name: 'Draft', value: 3 }
];

const SVR_RESPONSES_VISIBILITY = [
  { name: 'Yes', value: 0 },
  { name: 'No', value: 1 }
];

const Section = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  min-height      : 30px;
  padding         : 0 30px;
  border-bottom   : 2px solid #DCDCDC;
  cursor          : pointer;
`;

const InfoWrapper = styled.div`
  display         : flex;
  align-items     : center;
  justify-content : start-end;
  padding         : 0 40px;
`;

const InfoListWrapper = styled.div`
  width : 100%;
`;

const FieldRow = styled.div`
  display       : flex;
  align-items   : center;
  border-bottom : 1px solid #DCDCDC;
  font-size     : 13px;
  min-height    : 30px;

  p {
    width : 45%;
  }
`;

const RowWithErrors = styled.div`
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  border-bottom   : 1px solid #DCDCDC;
  font-size       : 13px;
  min-height      : 30px;
`;

const FieldName = styled.p`
  color: #868686;
`;

const RedTitle = styled.p`
  color      : #C92837;
  text-align : right;
  min-width  : 39%;
`;

const SvrResponseReviewInfo = ({
  toggleOpenSection,
  isSectionOpen,
  isSectionForcedOpen,
  svrResponse,
  // handleSelectState,
  handleSelectVisibility,
  isClientUser,
  appdate,
  rejecteddateandby,
  isrejectcnt
}) => svrResponse && (
  <div>
    <Section onClick={toggleOpenSection}>
      <p>SVR Information</p>
      {isSectionOpen || isSectionForcedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </Section>
    {(isSectionOpen || isSectionForcedOpen) && (
      <InfoWrapper>
        <InfoListWrapper>

          {!isClientUser && (
            <FieldRow>
              <FieldName>Status</FieldName>
              <p>{SVR_REVIEW_STATES[svrResponse.metaJson.reviewState]}</p>
            </FieldRow>
          )}

          {!isClientUser && (
            <FieldRow>
              <FieldName>Client</FieldName>
              <p>{svrResponse.metaJson.corporation}</p>
            </FieldRow>
          )}

          {/* <FieldRow>
            <FieldName>Project</FieldName>
            <p>{svrResponse.metaJson.projectName}</p>
          </FieldRow> */}

          <FieldRow>
            <FieldName>Project Segment Name</FieldName>
            <p>{svrResponse.metaJson.projectSegmentName}</p>
          </FieldRow>

          {!isClientUser && (
            <FieldRow>
              <FieldName>PD</FieldName>
              <p>{svrResponse.metaJson.ProjectDivision}</p>
            </FieldRow>
          )}

          <FieldRow>
            <FieldName>Retailer</FieldName>
            <p>{svrResponse.metaJson.retailer}</p>
          </FieldRow>

          <FieldRow>
            <FieldName>Store</FieldName>
            <p>{svrResponse.metaJson.doorLocation} ({svrResponse.metaJson.doorNumber})</p>
          </FieldRow>

          <FieldRow>
            <FieldName>Location</FieldName>
            <p>{svrResponse.metaJson.projectEventLocation}</p>
          </FieldRow>

          <FieldRow>
            <FieldName>Department</FieldName>
            <p>{svrResponse.metaJson.departmentType}</p>
          </FieldRow>

          <FieldRow>
            <FieldName>Visit Date</FieldName>
            {
              svrResponse.svrType === MULTI_DAY_SVR
                ? <p>{svrResponse.multiDayEvents && svrResponse.multiDayEvents.map(e => moment(e.expectedCheckinTime).format('MM/DD/YYYY')).join(', ')}</p>
                : <p>{svrResponse.expectedCheckinTime && moment(svrResponse.expectedCheckinTime).format('MM/DD/YYYY')}</p>
            }
          </FieldRow>

          <FieldRow>
            <FieldName>Visit Time</FieldName>
            {
              svrResponse.svrType === MULTI_DAY_SVR
                ? <p>{svrResponse.multiDayEvents && svrResponse.multiDayEvents.map(e => moment(e.expectedCheckinTime).format('h:mm a')).join(', ')}</p>
                : <p>{svrResponse.expectedCheckinTime && moment(svrResponse.expectedCheckinTime).format('h:mm a')}</p>
            }
          </FieldRow>

          {!isClientUser && (
            <FieldRow>
              <FieldName>Visit Duration</FieldName>
              {
                svrResponse.svrType === MULTI_DAY_SVR
                  ? <p>{svrResponse.multiDayEvents && svrResponse.multiDayEvents.map(e => toHours(e.expectedDurationMinutes)).join(', ')}</p>
                  : <p>{toHours(svrResponse.expectedDurationMinutes)}</p>
              }
            </FieldRow>
          )}

          {!isClientUser && (
            <FieldRow>
              <FieldName>Talent Name</FieldName>
              <p>{svrResponse.metaJson.mcFullName}</p>
            </FieldRow>
          )}


          {!isClientUser && (
            <FieldRow>
              <FieldName>TD</FieldName>
              <p>{svrResponse.metaJson.TalentDivision}</p>
            </FieldRow>
          )}

          {!isClientUser && svrResponse.svrType === NORMAL_SVR && (
            <RowWithErrors>
              <FieldName style={{ minWidth: '33%' }}>Check In</FieldName>
              <p>{svrResponse.actualCheckinTime ? moment(svrResponse.actualCheckinTime).format('h:mm a') : 'N/A'}</p>
              <RedTitle>{checkInLate(svrResponse.actualCheckinTime, svrResponse.expectedCheckinTime)}</RedTitle>
            </RowWithErrors>
          )}

          {!isClientUser && svrResponse.svrType === MULTI_DAY_SVR && (
            <FieldRow>
              <FieldName style={{ minWidth: '33%' }}>Check In</FieldName>
              <div style={{ display: "flex", flexDirection: "column", minWidth: '40%' }}>
                {svrResponse.multiDayEvents.map((event, i) => {
                  return (
                    <>
                      {i > 0 && <p>--------------</p>}
                      <div key={event.eventId} style={{ display: "flex", width: "100%" }}>
                        <p>{event.actualCheckinTime ? moment(event.actualCheckinTime).format('h:mm a') : "N/A"}</p>
                        <RedTitle>{checkInLate(event.actualCheckinTime, event.expectedCheckinTime)}</RedTitle>
                      </div>
                    </>
                  );
                })}
              </div>
            </FieldRow>
          )}

          {!isClientUser && svrResponse.svrType === NORMAL_SVR && (
            <RowWithErrors>
              <FieldName style={{ minWidth: '33%' }}>Check Out</FieldName>
              <p>{svrResponse.actualCheckoutTime && moment(svrResponse.actualCheckoutTime).format('h:mm a')}</p>
              <RedTitle>{checkOutLate(svrResponse.actualCheckoutTime, svrResponse.expectedCheckinTime, svrResponse.expectedDurationMinutes)}</RedTitle>
            </RowWithErrors>
          )}

          {!isClientUser && svrResponse.svrType === MULTI_DAY_SVR && (
            <FieldRow>
              <FieldName style={{ minWidth: '33%' }}>Check Out</FieldName>
              <div style={{ display: "flex", flexDirection: "column", minWidth: '33%' }}>
                {svrResponse.multiDayEvents.map((event, i) => {
                  return (
                    <>
                      {i > 0 && <p>--------------</p>}
                      <div key={event.eventId} style={{ display: "flex", width: "100%" }}>
                        <p>{event.actualCheckoutTime ? moment(event.actualCheckoutTime).format('h:mm a') : 'N/A'}</p>
                        <RedTitle>{checkOutLate(event.actualCheckoutTime, event.expectedCheckinTime, event.expectedDurationMinutes)}</RedTitle>
                      </div>
                    </>
                  );
                })}
              </div>
            </FieldRow>
          )}

          {!isClientUser && (
            <RowWithErrors>
              <FieldName style={{ minWidth: '33%' }}>Time Spent On SVR</FieldName>
              <p>{calculateDuration(svrResponse.actualCheckinTime, svrResponse.actualCheckoutTime)}</p>
              <RedTitle>{checkDelay(svrResponse.actualCheckinTime, svrResponse.actualCheckoutTime, svrResponse.expectedDurationMinutes)}</RedTitle>
            </RowWithErrors>
          )}

          {!isClientUser && (
            <FieldRow>
              <FieldName>Amount of Times Rejected</FieldName>
              <p>{ isrejectcnt ? R.pathOr('0', ['rejectedCounter'], svrResponse) : R.pathOr('0', ['rejectedCounter'], svrResponse) }</p>
            </FieldRow>
          )}

          {!isClientUser && (
            <FieldRow>
              <FieldName>Feedback</FieldName>
              <p>{R.pathOr('', ['formState', 'feedback', 'message'], svrResponse)}</p>
            </FieldRow>
          )}

          {!isClientUser && svrHasSubmissions(svrResponse) && (
            <FieldRow>
              <FieldName>Submitted/Resubmitted dates</FieldName>
              <p>{R.pathOr([], ['submissions'], svrResponse).map(s => moment(R.prop("date", s)).format('MM/DD/YYYY')).join(', ')}</p>
            </FieldRow>
          )}

          {!isClientUser && (
            <FieldRow>
              <FieldName>Approved By</FieldName>
              <p>{R.pathOr('NA', ['metaJson', 'reviewedBy'], svrResponse)}</p>
            </FieldRow>
          )}

          {!isClientUser && (
            <FieldRow>
              <FieldName>Authorized By</FieldName>
              <p>{R.pathOr('NA', ['approvedBy'], svrResponse)}</p>
            </FieldRow>
          )}

          {!isClientUser && (
            <FieldRow>
              <FieldName>Visible to client</FieldName>
              <Select
                value={R.pathOr('', [svrResponse.privacy, 'value'], SVR_RESPONSES_VISIBILITY)}
                onChange={handleSelectVisibility}
                style={{ fontSize: '13px' }}
                inputProps={{
                  name: 'age',
                  id: 'age-simple',
                }}
              >
                {SVR_RESPONSES_VISIBILITY.map(a => <MenuItem key={`key_${a.value}`} value={a.value}>{a.name}</MenuItem>)}
              </Select>
            </FieldRow>
          )}

          {!isClientUser && (
            <FieldRow>
              <FieldName>State</FieldName>
              <p>
                {
                  svrResponse.svrType === MULTI_DAY_SVR
                    ? svrResponse.multiDayEvents.map(event => SVR_RESPONSE_STATUSES.find(a => a.value === event.status).name).join(', ')
                    : SVR_RESPONSE_STATUSES.find(a => a.value === svrResponse.status).name
                }
              </p>
              {/* <Select
                value      = {R.pathOr('', [svrResponse.status, 'value'], SVR_RESPONSE_STATUSES)}
                onChange   = {handleSelectState}
                style      = {{ fontSize: '13px' }}
                inputProps = {{
                  name : 'age',
                  id   : 'age-simple',
                }}
              >
                {SVR_RESPONSE_STATUSES.map(a => <MenuItem key={`key_${a.value}`} value={a.value}>{a.name}</MenuItem>)}
              </Select> */}
            </FieldRow>
          )}


          {!isClientUser && (
            <FieldRow>
              <FieldName>Talent’s first Approved SVR for Winston </FieldName>
              {
                R.isNil(appdate) || R.isEmpty(appdate)
                  ? '' :
                  moment().diff(appdate.metaApprovedDate, 'days') > 30
                    ? <p style={{ color: 'green' }} >{R.isNil(appdate.metaApprovedDate) || R.isEmpty(appdate.metaApprovedDate) ? '' : moment(appdate.metaApprovedDate).format('MM/DD/YYYY')}</p>
                    :
                    <p>{R.isNil(appdate.metaApprovedDate) || R.isEmpty(appdate.metaApprovedDate) ? '' : moment(appdate.metaApprovedDate).format('MM/DD/YYYY')}</p>
              }
            </FieldRow>
          )}
          {!isClientUser && (
            <FieldRow>
              <FieldName>Rejected Date & Rejected by </FieldName>
              {
                <p>{rejecteddateandby.map(s => (R.prop("rejecteddate", s)).concat(R.isEmpty(R.prop("rejecteddate", s)) ? '' : '   -   ').concat(R.prop("rejectedby", s))).join(' , ')}</p>
              }
            </FieldRow>
          )}

        </InfoListWrapper>
      </InfoWrapper>
    )}
  </div>
);

const enhancePage = compose(
  withStateHandlers(
    ({
      isSectionOpen = false,
      selectedState = null,
      selectedVisibility = null,
    }) => ({
      isSectionOpen,
      selectedState,
      selectedVisibility,
    }),
    ({
      toggleOpenSection: ({ isSectionOpen }) => () => ({ isSectionOpen: !isSectionOpen }),
      handleSelectState: (__, { svrResponse, updateSvrResponse, namespace }) => ({ target }) => {
        updateSvrResponse(namespace, svrResponse.id, { status: target.value });
      },
      handleSelectVisibility: (__, { svrResponse, updateSvrResponse, namespace }) => ({ target }) => {
        updateSvrResponse(namespace, svrResponse.id, { privacy: target.value });
      },
    }),
  ),
);

export default enhancePage(SvrResponseReviewInfo);
