import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import {
  compose,
  withState,
  lifecycle,
} from 'recompose';


import Modal from './components/Modal';
import CannedResponses from './components/CannedResponses';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  & > label {
    font-weight: bold;
  }
  border: 1px whitesmoke solid!important;
`;

const SvrFeedbackModal = ({ onSave, onCancel, onChange, textFieldValue, setTextFieldValue, isAllApprove = false }) => (
  <Modal
    onSave={() => onSave && onSave(textFieldValue)}
    onCancel={onCancel}
    containerStyles={{ width: "850px" }}
    isAllApprove={isAllApprove}
  >
    {
      isAllApprove ?
        <Container>
          <p>Are you sure you want to mark the selected SVR's as Approved ?</p>
          <StyledTextField
            label="FEEDBACK"
            fullWidth
            multiline
            rows={10}
            id="title"
            value={textFieldValue}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            onChange={(e) => {
              setTextFieldValue(e.target.value);
              onChange && onChange(e);
            }}
          />
        </Container>
        :
        <Container>
          <h4>Canned responses:</h4>
          <CannedResponses onClickCannedResponse={value => {
            setTextFieldValue(prevValue => {
              const newValue = `${prevValue}${prevValue ? "\n\n" : ''}${value}`;
              onChange && onChange({ target: { value: newValue } });
              return newValue;
            });
          }} />
          <StyledTextField
            label="FEEDBACK"
            fullWidth
            multiline
            rows={10}
            id="title"
            value={textFieldValue}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            onChange={(e) => {
              setTextFieldValue(e.target.value);
              onChange && onChange(e);
            }}
          />
        </Container>
    }
  </Modal>
);

SvrFeedbackModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  feedbackMessage: PropTypes.string
};

SvrFeedbackModal.defaultProps = {
  feedbackMessage: '',
};

const withHandlers = compose(
  withState("textFieldValue", "setTextFieldValue", ""),
  lifecycle({
    componentDidMount() {
      this.props.feedbackMessage && this.props.setTextFieldValue(this.props.feedbackMessage);
    }
  })
);

export default withHandlers(SvrFeedbackModal);
