import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import * as R               from 'ramda';
import styled               from 'styled-components';
import moment               from 'moment';

import { withStyles } from '@material-ui/core/styles';
import { lighten }    from '@material-ui/core/styles/colorManipulator';
import {
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Grid
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import SortableTableByState from '../../../components/SortableTableByState';

import ChangesOnSvrModal       from './../../modals/ChangesOnSvrModal';
import ImpossibleEditSvrsModal from './../../modals/ImpossibleEditSvrsModal';
import { maybeDecorateColumn } from '../../../utils/tableColumns';
import theme from '../../../styles/theme';


const HEIGHT                       = 25;
const SVR_RESPONSE_APPROVED_STATUS = 3;

const DeleteIcon = styled(Delete)`
  margin-right : 20px;
  cursor       : pointer;
`;

const Footer = styled.div`
  display         : flex;
  height          : ${HEIGHT + 5}px;
  background      : #f8f8f8;
  justify-content : space-between;
  font-size       : 15px;
  align-items     : flex-end;
  padding-bottom  : 7px;
  & > div {
    color        : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598'};
    cursor       : pointer;
    margin-left  : 30px;
    margin-right : 30px;

    div {
      margin-right: 20px;
    }
  }
`;
const StyledTableRow = styled(TableRow)`
  border-top : 1px solid #C9CACB;
  height     : 30px !important;
`;

const isSortedBy  = (k, orderBy) => orderBy === k;
const maybeSortBy = (k, orderBy, order) => isSortedBy(k, orderBy) ? order : false;
const pluckPath   = R.curry((path, object) => R.map(R.path(path), object));

class EnhancedTableHead extends Component {
  createSortHandler = (pathStr, type) => event => {
    this.props.onRequestSort(event, pathStr, type);
  };

  render() {
    const {
      order,
      orderBy,
      tableColumns
    } = this.props;

    return (
      <TableHead>
        <StyledTableRow>
          {
            R.map(({ pathStr, label, type }) => (
              <TableCell
                key           = {pathStr}
                numeric       = {false}
                padding       = "dense"
                type          = "head"
                style         = {{ whiteSpace : 'nowrap', flexDirection : 'row' }}
                sortDirection = {maybeSortBy(pathStr, orderBy, order)}
              >
                <Tooltip
                  title      = "Sort"
                  placement  = "bottom-end"
                  enterDelay = {300}
                >
                  <TableSortLabel
                    active    = {isSortedBy(pathStr, orderBy)}
                    direction = {order}
                    onClick   = {this.createSortHandler(pathStr, type)}
                  >
                    { label }
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ))(tableColumns)
          }

        </StyledTableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected      : PropTypes.number.isRequired,
  onRequestSort    : PropTypes.func.isRequired,
  onSelectAllClick : PropTypes.func.isRequired,
  order            : PropTypes.string.isRequired,
  orderBy          : PropTypes.string.isRequired,
  rowCount         : PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root       : {
    paddingRight : theme.spacing.unit,
    minHeight    : '53px',
    padding      : '0px 8px 25px 15px'
  },
  highlight  :
    theme.palette.type === 'light'
      ? {
        color           : theme.palette.secondary.main,
        backgroundColor : lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color           : theme.palette.text.primary,
        backgroundColor : theme.palette.secondary.dark
      },
  spacer     : {
    flex : '1 1 100%'
  },
  actions    : {
    color : theme.palette.text.secondary
  },
  title      : {
    flex : '0 0 auto'
  },
  headerIcon : {
    textAlign : 'right',
    width     : '100%'
  }
});

let EnhancedTableToolbar = props => {
  const {
          numSelected,
          classes,
          title,
          description,
          onFilterByStatus,
          savedView,
          deleteSavedView
        } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <div>
            <Typography variant="title">
              { title || 'Meal Period' }
            </Typography>
            {
              description && (
                <Typography color="inherit" variant="subheading">
                  { description }
                </Typography>
              )
            }
          </div>
        )}
      </div>
      <div className={classes.headerIcon}>
        <SortableTableByState onFilterByStatus={onFilterByStatus} />
        {
          !R.isEmpty(savedView) && (
            <Tooltip
              title      = "Delete this saved view"
              placement  = "left-start"
              enterDelay = {300}
            >
              <DeleteIcon onClick={() => {
                deleteSavedView(savedView);
              }}/>
            </Tooltip>
          )
        }
      </div>

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes             : PropTypes.object.isRequired,
  numSelected         : PropTypes.number.isRequired,
  setShowSearchFilter : PropTypes.func,
  title               : PropTypes.string,
  description         : PropTypes.string,
  deleteSavedView     : PropTypes.func,
  savedView           : PropTypes.object
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width        : '96%',
    marginTop    : '80px',
    marginLeft   : 'auto',
    marginRight  : 'auto',
    marginBottom : '80px'
  },
  tableWrapper: {
    overflowX : 'auto',
  },
  mcInfo: {
    width        : '96%',
    marginLeft   : '10px',
    marginBottom : '5px'
  },
});

class EnhancedTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order                     : 'desc',
      orderBy                   : 'id',
      sortType                  : 'string',
      selected                  : [],
      page                      : 0,
      rowsPerPage               : props.rowsPerPage,
      showModal                 : false,
      changeApprove             : null,
      rowsPerPageOptions        : props.rowsPerPageOptions,
      isImpossibleEditSvrsModal : false,
      currentUserSvrResponses   : [],
      filterByStatus            : 0
    };
  }

  getStatusCode = label => {
    return R.compose(
      R.partialRight(parseInt, [10]),
      R.head,
      R.find(R.compose(R.equals(label), R.last)),
      R.toPairs
    )(window.WINSTON.statuses.storeVisit);
  };

  getReviewState = label => {
    return R.compose(
      R.partialRight(parseInt, [10]),
      R.head,
      R.find(R.compose(R.equals(label), R.last)),
      R.toPairs
    )(window.WINSTON.statuses.reviewState);
  };

  deleteSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Deleted'));
  };

  resetSvrResponses = () => {
    this.resetSvrResponses();
  };

  activeSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Active'));
  };

  archiveSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Archived'));
  };

  approveSvrResponses = () => {
    const { onUpdate, data, userEmail, namespace } = this.props;
    const { selected } = this.state;

    const svrResponsesFilter = s => R.contains(s.id, selected) && !R.equals(R.path(['metaJson', 'reviewState'], s), SVR_RESPONSE_APPROVED_STATUS);
    const updatedMetaJson    = svrResponse => R.assoc('reviewState', SVR_RESPONSE_APPROVED_STATUS, svrResponse.metaJson);
    const updateSvrResponse  = svrResponse => onUpdate(namespace, svrResponse.id, { metaJson: JSON.stringify(updatedMetaJson(svrResponse)) });

    const svrResponses            = R.filter(svrResponsesFilter, data);
    const currentUserSvrResponses = R.filter(s => R.equals(R.path(['metaJson', 'mcEmail'], s), userEmail), svrResponses);

    if (currentUserSvrResponses.length > 0) {
      this.setState({
        isImpossibleEditSvrsModal: true,
        currentUserSvrResponses
      });
    } else {
      R.forEach(updateSvrResponse, svrResponses);

      this.setState({
        selected: []
      });
    }
  };

  modalClose = () => {
    this.setState({
      showModal                 : false,
      changeApprove             : null,
      isImpossibleEditSvrsModal : false,
      currentUserSvrResponses   : []
    });
  };

  updateSvrResponses = status => {
    const { onUpdate, data, namespace } = this.props;

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(svrResponse => {
          data.forEach(item => {
            if (R.equals(svrResponse, item.id)) {
              if (!R.equals(item.status, status)) {
                onUpdate(namespace, item.id, { status: parseInt(status, 10) });
              }
            }
          });
        });
        this.modalClose();
        this.setState({ selected : [] });
      };

      this.setState({
        showModal     : true,
        changeApprove : change
      });
    }
  };

  resetSvrResponses = () => {
    const { onUpdate, data, namespace } = this.props;

    const RESET_DATA = {
      status    : this.getStatusCode('Active'),
      metaJson  : JSON.stringify({
        reviewState       : this.getReviewState('Pending Submission'),
        awaitingOverwrite : 'Reset via the webclient UI'
      }),
      formId    : null,
      formState : JSON.stringify({
        feedback         : null,
        visitedQuestions : null
      }),
      actualCheckinLatitude   : null,
      actualCheckinLongitude  : null,
      actualCheckinDistanceKm : null,
      actualCheckinTime       : null,
      actualCheckoutTime      : null,
      approvedBy              : ''
    };

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(svrResponse => {
          data.forEach(item => {
            if (R.equals(svrResponse, item.id)) {
              onUpdate(namespace, item.id, RESET_DATA);
            }
          });
        });
        this.modalClose();
        this.setState({ selected : [] });
      };

      this.setState({
        showModal     : true,
        changeApprove : change
      });
    }
  };

  componentDidUpdate(prevProps) {
    const transformedDataIsDifferent = !R.equals(R.pluck('timestamp', prevProps.transformedData), R.pluck('timestamp', this.props.transformedData));
    const filtersAreDifferent        = !R.equals(prevProps.filterList, this.props.filterList);
    const dataIsDifferent            = !R.equals(R.pluck('timestamp', prevProps.data), R.pluck('timestamp', this.props.data));

    if (transformedDataIsDifferent || filtersAreDifferent || dataIsDifferent) {

      const order      = this.state.order      || 'asc',
            orderBy    = this.state.orderBy    || 'id',
            sortType   = this.state.sortType   || 'string',
            filterList = this.props.filterList || {};

      this.props.transformData(orderBy, order, sortType, filterList, this.props.data, this.props.namespace);
    }

  }

  handleRequestSort = (event, pathStr, sortType) => {
    const orderBy = pathStr || '';

    let order = 'desc';

    if (this.state.orderBy === pathStr && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, sortType });

    this.props.transformData(orderBy, order, sortType, this.props.filterList, this.props.data, this.props.namespace);
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: pluckPath(['mealPeriod', 'id'], this.props.transformedData) });
      this.props.handleSelectData(pluckPath(['mealPeriod', 'id'], this.props.transformedData));
      return;
    }
    this.setState({ selected: [] });
    this.props.handleSelectData([]);
  };

  handleClick = (event, id) => {
    event.stopPropagation();

    const { selected }   = this.state;
    const selectedIndex  = selected.indexOf(id);
    const firstItemIndex = 0;
    const lastItemIndex  = selected.length - 1;

    const newSelected = R.defaultTo(
      [],
      R.cond([
        [R.equals(-1),             () => [].concat(selected, id)],
        [R.equals(firstItemIndex), () => [].concat(selected.slice(1))],
        [R.equals(lastItemIndex),  () => [].concat(selected.slice(0, -1))],
        [R.lt(0),                  () => [].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))],
      ])(selectedIndex)
    );

    this.setState({ selected: newSelected });
    this.props.handleSelectData(newSelected);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage : event.target.value });
  };

  isSelected = id => R.contains(id, this.state.selected);

  onFilterByStatus = filterByStatus => {
    this.setState({ filterByStatus });
  };

  render() {
    const {
            classes,
            tableColumns,
            transformedData,
            setShowSearchFilter,
            showSearchFilter,
            title,
            description,
            savedView,
            deleteSavedView,
            isClientUser
          } = this.props;

    const {
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            showModal,
            changeApprove,
            rowsPerPageOptions,
            filterByStatus,
            isImpossibleEditSvrsModal,
            currentUserSvrResponses
          } = this.state;

    const filteredByStatusData = () => {
      if (filterByStatus) {
        return transformedData.filter(d => d.status === filterByStatus).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }

      return transformedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected         = {selected.length}
          selectedIds         = {selected}
          setShowSearchFilter = {setShowSearchFilter}
          showSearchFilter    = {showSearchFilter}
          title               = {title}
          description         = {description}
          onFilterByStatus    = {this.onFilterByStatus}
          savedView           = {savedView}
          deleteSavedView     = {deleteSavedView}
        />
        <div className={classes.tableWrapper}>
          {showModal &&
            <ChangesOnSvrModal
              onApprove = {changeApprove}
              onCancel  = {this.modalClose}
              items     = {selected.length}
            />
          }
          {isImpossibleEditSvrsModal &&
            <ImpossibleEditSvrsModal
              onApprove={this.modalClose}
              currentUserSvrResponses={currentUserSvrResponses}
              withoutCancel
            />
          }
          {filteredByStatusData()
              .map(mcVisits => {
                const mc      = R.head(mcVisits),
                      mcid    = R.prop('metaMcid', mc),
                      mcPhone = R.compose(R.path(['metaJson', 'mcPhone']), R.last, R.filter(R.path(['metaJson', 'mcPhone'])))(mcVisits);
                return (
                  <div key={mcid}>
                    <Grid container spacing={8} className={classes.mcInfo}>
                      <Grid item>
                        <Typography color="inherit" >
                          { R.prop('metaMcFullName', mc) }
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="inherit" >
                         MCID:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="inherit" >
                         { mcid }
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="inherit" >
                          Phone:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <a href={`tel:${mcPhone}`}>
                          { mcPhone }
                        </a>
                      </Grid>
                      <Grid item>
                        <Typography color="inherit" >
                          Email:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <a href={`mailto:${R.prop('metaMcEmail', mc)}`}>
                          { R.prop('metaMcEmail', mc) }
                        </a>
                      </Grid>
                    </Grid>
                    {R.compose(
                      R.values,
                      R.mapObjIndexed((visits, key) => {
                          return (
                            <div key={`${mcid}-${key}`}>
                              <Grid container spacing={8} className={classes.mcInfo}>
                                <Grid item>
                                  <Typography color="inherit" className={classes.mcInfo} variant="subheading">
                                    { key }
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography color="inherit" >
                                  Worked Hours:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography color="inherit" >
                                    { R.pipe(R.sum, R.divide(R.__, 60))(R.pluck('expectedDurationMinutes', visits)) }
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography color="inherit" >
                                  Total Breaks:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography color="inherit" >
                                    { R.defaultTo(0, R.pipe(R.map(R.length), R.sum)(R.pluck('metaMealBreak', visits))) }
                                  </Typography>
                                </Grid>
                              </Grid>
                              {
                                visits.map(visit => {
                                  const visitId             = R.prop('id', visit);
                                  const nextVisitId         = R.prop('nId', visit);
                                  const mealBreaks          = R.pathOr([], ['metaMealBreak'], visit);
                                  const isSelected          = this.isSelected(visitId);
                                  
                                  const visitTableColumns = R.reject(R.compose(
                                                              p => R.contains('mealBreak') && R.length(p) > 2,
                                                              R.prop('path')
                                                            ), tableColumns);
                                  const mealBreakColumns  = R.filter(R.compose(
                                                              p => R.contains('mealBreak') && R.length(p) > 2,
                                                              R.prop('path')
                                                            ), tableColumns);
                                  return (
                                    <div
                                      key          = {`${visitId}-${nextVisitId}`}>
                                      <Table>
                                        <EnhancedTableHead
                                          tableColumns     = {visitTableColumns}
                                          numSelected      = {selected.length}
                                          order            = {order}
                                          orderBy          = {orderBy}
                                          onSelectAllClick = {this.handleSelectAllClick}
                                          onRequestSort    = {this.handleRequestSort}
                                          rowCount         = {transformedData.length}
                                        />
                                        <TableBody>
                                          <TableRow
                                            hover
                                            aria-checked = {isSelected}
                                            tabIndex     = {-1}
                                            key          = {`${visitId}-${nextVisitId}`}
                                            selected     = {isSelected}
                                            style        = {{ height: `${HEIGHT}px`, cursor: 'pointer', whiteSpace: 'nowrap' }}
                                          >
                                          {
                                            R.map(({ path, decorator }) => (
                                              <TableCell
                                                key     = {path}
                                                padding = "dense"
                                                numeric = {false}
                                                type    = "body"
                                              >{maybeDecorateColumn(decorator)(R.path(path, visit))}</TableCell>
                                            ))(visitTableColumns)
                                          }
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                      {mealBreaks.length > 0 &&
                                        <Table>
                                          <EnhancedTableHead
                                            tableColumns     = {mealBreakColumns}
                                            numSelected      = {0}
                                            onRequestSort    = {() => {}}
                                            onSelectAllClick = {() => {}}
                                            order            = {order}
                                            orderBy          = {orderBy}
                                            rowCount         = {mealBreaks.length}
                                          />
                                          <TableBody>
                                            {mealBreaks.map((mealBreak, idx) => {
                                              const maybeDisabledStyle  = R.cond([
                                                [R.equals(0), R.always({backgroundColor: "#88FF55CC"})],
                                                [R.equals(1), R.always({backgroundColor: "#FF0000CC"})],
                                                [R.T, R.always({})]
                                              ])(R.prop('waived', mealBreak));
                                            return (
                                              <TableRow
                                                key          = {`${visitId}-${nextVisitId}-${idx}`}
                                                hover
                                                aria-checked = {isSelected}
                                                tabIndex     = {-1}
                                                selected     = {isSelected}
                                                style        = {{ height: `${HEIGHT}px`, cursor: 'pointer', whiteSpace: 'nowrap', ...maybeDisabledStyle }}
                                              >
                                              {
                                                R.map(({ path, decorator }) => (
                                                  <TableCell
                                                    key     = {path}
                                                    padding = "dense"
                                                    numeric = {false}
                                                    type    = "body"
                                                  >{maybeDecorateColumn(decorator)(R.prop(R.last(path), mealBreak))}</TableCell>
                                                ))(mealBreakColumns)
                                            }
                                              </TableRow>
                                            );
                                          })}
                                          </TableBody>
                                        </Table>
                                      }
                                    </div>
                                  );
                                })
                              }
                              <div style={{ height : '20px' }}/>
                            </div>
                          );
                      }),
                      R.groupBy(v => moment(R.prop('expectedCheckinTime', v)).format('YYYY-MM-DD'))
                    )(mcVisits)}
                    <div style={{ height : '30px' }}/>
                  </div>
                );
              })
            }
        </div>
        <div>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan             = {1}
                  count               = {transformedData.length}
                  style               = {{ height : `${HEIGHT}px` }}
                  rowsPerPage         = {rowsPerPage}
                  rowsPerPageOptions  = {rowsPerPageOptions}
                  page                = {page}
                  onChangePage        = {this.handleChangePage}
                  onChangeRowsPerPage = {this.handleChangeRowsPerPage}
                  backIconButtonProps = {{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps = {{
                    'aria-label': 'Next Page',
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        {
          !isClientUser ? (
            <Footer isSelected={selected.length > 0}>
             <div style={{ display: 'flex' }}>
                <div style={{ color: theme.palette.pblack.black }} onClick={this.archiveSvrResponses}>ARCHIVE</div>
                <div style={{ color: theme.palette.pblack.black }} onClick={this.deleteSvrResponses}>DELETE</div>
                <Tooltip
                  title      = "After resetting visits here, you can update them in BOS to be pulled down on the next sync."
                  placement  = "top-start"
                  enterDelay = {300}
                >
                  <div style={{ color: theme.palette.pblack.black }} onClick={this.resetSvrResponses}>RESET</div>
                </Tooltip>
                <div style={{ color: theme.palette.pblack.black }} onClick={this.activeSvrResponses}>ACTIVATE</div>
              </div>
              {/* <div style={{ color: theme.palette.pblack.black }} onClick={this.approveSvrResponses}>APPROVE</div> */}
            </Footer>
          ) : null
        }
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  data                : PropTypes.array,
  transformData       : PropTypes.func.isRequired,
  transformedData     : PropTypes.array,
  transformedDataDict : PropTypes.object,
  tableColumns        : PropTypes.array,
  rowsPerPage         : PropTypes.number,
  classes             : PropTypes.object.isRequired,
  rowsPerPageOptions  : PropTypes.array.isRequired,
  setShowSearchFilter : PropTypes.func,
  deleteSavedView     : PropTypes.func,
  savedView           : PropTypes.object,
  isClientUser        : PropTypes.bool
};

EnhancedTable.defaultProps = {
  data                : [],
  transformedData     : [],
  transformedDataDict : {},
  tableColumns        : [],
  rowsPerPage         : 50,
  filterList          : {},
  showSearchFilter    : false,
  rowsPerPageOptions  : [25, 50, 100, 250]
};

export default withStyles(styles)(EnhancedTable);
