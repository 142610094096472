import React       from 'react';
import PropTypes   from 'prop-types';
import { Helmet }  from 'react-helmet';
import { connect } from 'react-redux';
import * as R      from 'ramda';

import {
  compose,
  lifecycle,
  withStateHandlers,
  defaultProps,
  withHandlers,
  withState
} from 'recompose';

import Header                   from '../../../components/Header';
import DownloadZipProgressModal from '../../modals/DownloadZipProgressModal';
import SvrResponseReviewToolbar from '../components/review/SvrResponseReviewToolbar';
import SvrResponseReview        from '../components/review/SvrResponseReview';
import FileExportProgressModal  from '../../modals/FileExportProgressModal';

import { getEmail } from '../../auth/AuthReducer';

import {
  exportToFile        as _exportToFile,
  hideFileExportModal as _hideFileExportModal
} from '../../file-export/FileExportActions';

import {
  getShowFileExportModal,
  getFileExportError,
  getFileExportLink
} from '../../file-export/FileExportReducer';

import {
  getClients,
  getCurrentClient,
  getCurrentProject,
  isClientUser
} from '../../client/ClientReducer';

import {
  setCurrentClient  as _setCurrentClient,
  setCurrentProject as _setCurrentProject,
} from '../../client/ClientActions';

import {
  fetchSvrById as _fetchSvrById
} from '../../svr/SvrActions';

import {
  getSvrs,
  getQuickReviewForm
} from '../../svr/SvrReducer';

import {
  hideDownloadZipModal as _hideDownloadZipModal,
  downloadZip          as _downloadZip,
  updateDocument       as _updateDocument
} from '../../document/DocumentActions';

import {
  getShowDownloadZipModal,
  getZipError,
  getZipLink
} from '../../document/DocumentReducer';

import {
  fetchSvrResponse        as _fetchSvrResponse,
  fetchSvrResponses       as _fetchSvrResponses,
  updateSvrResponse       as _updateSvrResponse,
  fetchFormAnswers        as _fetchFormAnswers,
  fetchPhotoQuestions     as _fetchPhotoQuestions,
  fetchVideoQuestions     as _fetchVideoQuestions,
  fetchMediaQuestions     as _fetchMediaQuestions,
  updateFormAnswer        as _updateFormAnswer,
  createFormAnswer        as _createFormAnswer,
  selectNavigateFilter    as _selectNavigateFilter,
  setQuestions            as _setQuestions,
  svrResponseRejectby     as _svrResponseRejectby
} from '../SvrResponsesActions';

import {
  getSvrResponse,
  getQuestions,
  getPhotoQuestions,
  getVideoQuestions,
  getQuestionAnswers,
  getSignatureQuestions,
  getFormAnswers,
  getTransformedData,
  getSvrResponses,
  getNavigateFilter,
  getSvrPhotos,
  getSvrVideos,
  getSvrSignatures,
  getTableColumns
} from '../SvrResponsesReducer';
import { Dialog, Typography, DialogContent, DialogTitle } from '@material-ui/core';
import { axiosClient }           from '../../../store';

const IN_REVIEW_STATUS = 2;
const NAMESPACE = 'svrResponses';

const SvrResponseReviewPage = (
  {
    clients,
    currentClient,
    currentProject,
    setCurrentClient,
    setCurrentProject,
    svrResponse,
    svrForm,
    updateSvrResponse,
    fetchPhotoQuestions,
    fetchVideoQuestions,
    formAnswers,
    photos,
    signatures,
    photoQuestions,
    signatureQuestions,
    videos,
    videoQuestions,
    questions,
    questionAnswers,
    quickReviewQuestions,
    updateFormAnswer,
    createFormAnswer,
    userEmail,
    downloadZip,
    showDownloadZipModal,
    hideDownloadZipModal,
    zipLink,
    zipError,
    fileExportLink,
    fileExportError,
    showFileExportModal,
    hideFileExportModal,
    history,
    transformedData,
    selectNavigateFilter,
    navigateFilter,
    updateDocument,
    exportToFile,
    isClientUser,
    tableColumns,
    isMetaSectionOpen,
    toggleMetaSection,
    namespace,
    isFetchingData,
    currentIds,
    navIndex,
    storeVisitId,
    appdate,
    svrResponseRejectby,
    rejecteddateandby,
    isrejectcnt
  }) => {
    const isClient = isClientUser();
    questions = R.reject(q => isClient && R.pathEq(['meta', 'isHidden'], true, q), questions);
    questionAnswers = R.compose(
      R.objOf(storeVisitId),
      R.filter(a => R.find(R.propEq('uuid', R.prop('questionUuid', a)), questions)),
      R.propOr([], storeVisitId)
    )(questionAnswers);

    return (
      <div>
        <Helmet>
          <title>Review Svr Response</title>
        </Helmet>
        <Header
          clients           = {clients}
          currentClient     = {currentClient}
          currentProject    = {currentProject}
          setCurrentClient  = {setCurrentClient}
          setCurrentProject = {setCurrentProject}
          isClientUser      = {isClientUser()}
        />
        <SvrResponseReviewToolbar
          data           = {transformedData}
          history        = {history}
          id             = {svrResponse.id}
          currentIds     = {currentIds}
          navIndex       = {navIndex}
          onFilterChange = {selectNavigateFilter}
          navigateFilter = {navigateFilter}
          svrResponse    = {svrResponse}
          isReadyData    = {questions && questionAnswers && photos}
          onExport       = {type => {

            toggleMetaSection(true);

            setTimeout(() => {
              exportToFile({
                type,
                questions,
                questionAnswers,
                photos,
                tableColumns,
                domId            : 'svr-response-wrapper',
                title            : `Reporting Store Visit ${svrResponse.id}`,
                transformedData  : R.filter(R.eqProps('id', svrResponse))(transformedData),
                isSingleDownload : true,
                namespace        : NAMESPACE,
                callback         : () => toggleMetaSection(false),
              });
            }, 250);
          }}
        />
        {
          showFileExportModal && (
            <FileExportProgressModal
              fileExportLink  = {fileExportLink}
              fileExportError = {fileExportError}
              onApprove       = {hideFileExportModal}
            />
          )
        }
        {
          showDownloadZipModal && (
            <DownloadZipProgressModal
              zipLink   = {zipLink}
              zipError  = {zipError}
              onApprove = {hideDownloadZipModal}
            />
          )
        }
        <SvrResponseReview
          svrResponse             = {svrResponse}
          svrForm                 = {svrForm}
          photos                  = {photos}
          signatures              = {signatures}
          photoQuestions          = {photoQuestions}
          signatureQuestions      = {signatureQuestions}
          videos                  = {videos}
          videoQuestions          = {videoQuestions}
          questions               = {questions}
          updateSvrResponse       = {updateSvrResponse}
          fetchPhotoQuestions     = {fetchPhotoQuestions}
          fetchVideoQuestions     = {fetchVideoQuestions}
          formAnswers             = {formAnswers}
          quickReviewQuestions    = {quickReviewQuestions}
          updateFormAnswer        = {updateFormAnswer}
          createFormAnswer        = {createFormAnswer}
          reviewMode              = {R.equals(IN_REVIEW_STATUS, R.pathOr(null, ['metaJson', 'reviewState'], svrResponse))}
          userEmail               = {userEmail}
          isClientUser            = {isClientUser}
          downloadZip             = {downloadZip}
          updateDocument          = {updateDocument}
          isMetaSectionOpen       = {isMetaSectionOpen}
          namespace               = {namespace}
          storeVisitId            = {storeVisitId}
          appdate                 = {appdate}
          svrResponseRejectby     = {svrResponseRejectby}
          rejecteddateandby       = {rejecteddateandby}
          isrejectcnt             = {isrejectcnt}
        />

        <Dialog
          open    = {isFetchingData}
          fullWidth = {true}
        >
          <DialogTitle>Loading...</DialogTitle>
          <DialogContent style={{ justifyContent : 'space-between' }}>
            <Typography>Please wait</Typography>
          </DialogContent>
        </Dialog>
      </div>
    );
};

SvrResponseReviewPage.propTypes = {
  clients              : PropTypes.array,
  currentClient        : PropTypes.object,
  currentProject       : PropTypes.object,
  isClientUser         : PropTypes.func,
  svrResponse          : PropTypes.object,
  svrForm              : PropTypes.object,
  zipLink              : PropTypes.string,
  zipError             : PropTypes.string,
  showDownloadZipModal : PropTypes.bool,
  fileExportLink       : PropTypes.string,
  fileExportError      : PropTypes.string,
  showFileExportModal  : PropTypes.bool,
  isMetaSectionOpen    : PropTypes.bool
};

const mapStateToProps = (state, props) => ({
    clients              : getClients(state),
    currentClient        : getCurrentClient(state),
    currentProject       : getCurrentProject(state),
    svrResponse          : getSvrResponse(state, props.namespace || NAMESPACE, props.match.params.id),
    transformedData      : R.filter(d => !R.isNil(d.formId), getTransformedData(state, props.namespace || NAMESPACE)),
    svrForm              : getSvrs(state, props.namespace || NAMESPACE)[0],
    formAnswers          : getFormAnswers(props.namespace || NAMESPACE)(state),
    questions            : getQuestions(state, props.namespace || NAMESPACE),
    questionAnswers      : getQuestionAnswers(state, props.namespace || NAMESPACE),
    quickReviewQuestions : getQuickReviewForm(state, props.namespace || NAMESPACE),
    photos               : getSvrPhotos(props.match.params.id)(state, props.namespace || NAMESPACE),
    signatures           : getSvrSignatures(props.match.params.id)(state, props.namespace || NAMESPACE),
    photoQuestions       : getPhotoQuestions(props.namespace || NAMESPACE)(state),
    videos               : getSvrVideos(props.match.params.id)(state, props.namespace || NAMESPACE),
    videoQuestions       : getVideoQuestions(props.namespace || NAMESPACE)(state),
    signatureQuestions   : getSignatureQuestions(props.namespace || NAMESPACE)(state),
    userEmail            : getEmail(state),
    showDownloadZipModal : getShowDownloadZipModal(state),
    zipLink              : getZipLink(state),
    zipError             : getZipError(state),
    showFileExportModal  : getShowFileExportModal(state),
    fileExportLink       : getFileExportLink(state),
    fileExportError      : getFileExportError(state),
    navigateFilter       : getNavigateFilter(state),
    svrResponses         : getSvrResponses(state, props.namespace || NAMESPACE),
    tableColumns         : getTableColumns(props.namespace || NAMESPACE)(state),
    currentIds           : R.pathOr([], ['location', 'state', 'ids'], props),
    navIndex             : R.pathOr(-1, ['location', 'state', 'navIndex'], props),
    isClientUser,
    storeVisitId         : props.match.params.id
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setCurrentClient     : payload => dispatch(_setCurrentClient(payload)),
  setCurrentProject    : payload => dispatch(_setCurrentProject(payload)),
  setQuestions         : (namespace, payload) => dispatch(_setQuestions(namespace, payload)),
  downloadZip          : payload => dispatch(_downloadZip(payload)),
  hideDownloadZipModal : payload => dispatch(_hideDownloadZipModal(payload)),
  hideFileExportModal  : payload => dispatch(_hideFileExportModal(payload)),
  selectNavigateFilter : payload => dispatch(_selectNavigateFilter(payload)),
  exportToFile         : payload => dispatch(_exportToFile(payload))
});

const enhancePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
       isMetaSectionOpen = false,
       isFetchingData = false
     }) => ({
      isMetaSectionOpen,
      isFetchingData
    }),
    ({
      toggleMetaSection : () => bool => ({ isMetaSectionOpen : bool }),
      setFetchingData : () => fetching => ({
        isFetchingData: fetching
      })
    })
  ),
  withHandlers({
    fetchSvrResponse    : props => (namespace, payload) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchSvrResponse(namespace, payload)))
      .then(data => {
        props.setFetchingData(false);
        return Promise.resolve(data);
      });
    },
    fetchSvrResponses   : props => (namespace, filters) => {
      Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchSvrResponses(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    fetchSvrById         : props => (namespace, payload) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchSvrById(namespace, payload)))
      .then(() => props.setFetchingData(false));
    },
    updateSvrResponse    : props => (namespace, svrResponseId, payload) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_updateSvrResponse(namespace, svrResponseId, payload)))
      .then(() => props.setFetchingData(false));
    },
    svrResponseRejectby: props => (namespace, payload) => {
      return Promise.resolve(/*props.setFetchingData(true)*/)
        .then(() => props.dispatch(_svrResponseRejectby(namespace, payload)))
        .then(/*() => props.setFetchingData(false)*/);
    },
    updateDocument       : props => (documentUuid, payload, namespace) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_updateDocument(documentUuid, payload, namespace)))
      .then(() => props.setFetchingData(false));
    },
    fetchFormAnswers     : props => (namespace, payload) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchFormAnswers(namespace, payload)))
      .then(data => {
        props.setFetchingData(false);
        return Promise.resolve(data);
      });
    },
    fetchPhotoQuestions  : props => (namespace, formAnswers, formIds) => svrResponseId => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchPhotoQuestions(namespace, formAnswers, svrResponseId, formIds)))
      .then(() => props.setFetchingData(false));
    },
    fetchVideoQuestions  : props => (namespace, formAnswers, formIds) => svrResponseId => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchVideoQuestions(namespace, formAnswers, svrResponseId, formIds)))
      .then(() => props.setFetchingData(false));
    },
    fetchMediaQuestions  : props => (namespace, formAnswers, formIds) => svrResponseId => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchMediaQuestions(namespace, formAnswers, svrResponseId, formIds)))
      .then(() => props.setFetchingData(false));
    },
    updateFormAnswer     : props => (namespace, answerValue, answerId) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_updateFormAnswer(namespace, answerValue, answerId)))
      .then(() => props.setFetchingData(false));
    },
    createFormAnswer     : props => payload => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_createFormAnswer(payload)))
      .then(() => props.setFetchingData(false));
    }
  }),
  defaultProps({
    currentClient        : {},
    currentProject       : {},
    clients              : [],
    svrResponse          : {},
    svrForm              : {},
    zipLink              : null,
    zipError             : null,
    showDownloadZipModal : false,
    fileExportLink       : null,
    fileExportError      : null,
    showFileExportModal  : false,
    isMetaSectionOpen    : false,
    namespace            : NAMESPACE,
    appdate              :[],
    rejecteddateandby    :[],
    isrejectcnt          : false
  }),
  withState('appdate', 'setappdate', []),
  withState('rejecteddateandby', 'setrejecteddateandby', []),
  withState('isrejectcnt', 'setisrejectcnt', false),  
  lifecycle({
    componentDidMount() {
      const id = R.path(['match', 'params', 'id'], this.props);

      if (id) {

        return Promise.resolve().then(() => this.props.fetchSvrResponse(this.props.namespace, id))
          .then(async (data)  => {
            const storeVisit = R.pathOr({}, ['payload', 'data', 'data'], data);
            const { formId } = storeVisit;

            const resp = await axiosClient.get(`/api/v2/talent/talentapproveddate/${storeVisit.metaMcid}`, {
              headers: {
                Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
              }
             });   

              if(!R.isNil(resp.data.data))
              {
                 this.props.setappdate(resp.data.data[0]);
              }

              const respRejectedSvr = await axiosClient.get(`/api/v2/svrrejectedbyusers/getrejectedbyusers/id/${id}`, {
                headers: {
                  Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
                }
              });
              
              if (respRejectedSvr.data.data !== null && respRejectedSvr.data.data !== undefined && respRejectedSvr.data.data.length > 0) {
                if (!R.isNil(respRejectedSvr.data.data)) {
                  this.props.setrejecteddateandby(respRejectedSvr.data.data);
                  this.props.setisrejectcnt(true);
                }
              }
              else if (respRejectedSvr.data.data !== null && respRejectedSvr.data.data !== undefined && respRejectedSvr.data.data.length === undefined) {
                const objdata = respRejectedSvr.data.data;
                
                if (!R.isNil(objdata.err)) {
                  const dataempty = {
                    createdby: "",
                    eventid: "",
                    rejecteddate: "",
                    rejectedby: "",
                    userid: ""
                  }
                  const datacc = [];
                  datacc.push(dataempty)
                  this.props.setrejecteddateandby(datacc);
                  this.props.setisrejectcnt(false);
                }
                else {
                  const dataReject = {
                    createdby: objdata.storevisitid,
                    eventid: objdata.eventid,
                    rejecteddate: objdata.rejecteddate,
                    rejectedby: objdata.rejectedby,
                    userid: objdata.userid
                  }
                  const datacc = [];
                  datacc.push(dataReject)
                  this.props.setrejecteddateandby(datacc);
                  this.props.setisrejectcnt(true);
                }
              }
  

            if (formId) {
              this.props.fetchSvrById(this.props.namespace, formId)
                .then(() => this.props.fetchFormAnswers(this.props.namespace, id))
                .then(res => {
                  const formAnswers       = R.pathOr([], ['payload', 'data', 'data'], res),
                        formQuestionUuids = R.pluck('questionUuid', formAnswers);

                  this.props.setQuestions(this.props.namespace, formQuestionUuids);
                  return this.props.fetchMediaQuestions(this.props.namespace, formAnswers, [formId])(id);
                });
            }
          });
      }
    }
  })
);

export default enhancePage(SvrResponseReviewPage);
