import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import moment from 'moment';

import {
  getTableColumnsForPage,
  maybeDecorateColumn
} from '../../utils/tableColumns';

import { isClientUser } from '../client/ClientReducer';
import recursivelyPluck from '../svr/utils/recursivelyPluck';

import {
  makeDecoratedImageUrl,
  getRotation
} from '../../utils/image';

import {
  FETCH,
  FETCH_ALL,
  DELETE,
  UPDATE,
  SET_QUESTIONS,
  CREATE_FORM_ANSWER,
  FETCH_FORM_ANSWERS,
  UPDATE_FORM_ANSWER,
  FETCH_DOCUMENTS,
  RESET_PHOTOS_REDUCER,
  FETCH_PHOTO_QUESTIONS,
  RESET_VIDEO_REDUCER,
  FETCH_VIDEO_QUESTIONS,
  RESET_MEDIA_REDUCER,
  FETCH_MEDIA_QUESTIONS,
  FILTER_BY_ACTION,
  TRANSFORM_DATA,
  SHOW_SEARCH_FILTER,
  SELECT_NAVIGATE_FILTER,
  CLEAR_CURRENT_DATA,
   RESPONSEREJECT
} from './SvrResponsesActions';

import {
  UPDATE_DOCUMENT
} from '../document/DocumentActions';

import {
  FETCH as FETCH_FORM
} from '../svr/SvrActions';

import getFlattenedQuestions from '../saved-rollups/components/utils/getFlattenedQuestions';

export const STATE_KEY = 'svrResponses';

const HIDDEN_FROM_CLIENT = R.path(['WINSTON', 'svrPrivacy', 'HIDDEN_FROM_CLIENT'])(window);

const SVR_RESPONSES_STATUSES = {
  PENDING_SUBMISSION : 0,
  PENDING_REVIEW     : 1,
  IN_REVIEW          : 2,
  APPROVED           : 3,
  REJECTED           : 4,
  RESUBMITTED        : 5
};

const SVR_WORK_CLASSIFICATION = {
  DEFAULT: '',
  EMPLOYEE : 'Employee',
  CONTRACTOR : 'Contractor'
};

const SVR_RESPONSES_STATUSES_COLORS = {
  RED : '#ff8080',
  ORANGE : '#ffd280',
  YELLOW : '#ffff99',
  TRANSPARENT : 'transparent'
};

// const SVR_RESPONSES_STATUSES_COLORS = {
//   RED : 'transparent',
//   ORANGE : 'transparent',
//   YELLOW : 'transparent',
//   TRANSPARENT : 'transparent'
// };

const initialState = {
  data            : {},
  transformedData : {},
  tableColumns    : [],
  formAnswers     : {},
  photos          : [],
  photoQuestions  : [],
  videos          : [],
  videoQuestions  : [],
  savedView       : {},
  navigateFilter  : null,
  filterList      : {}
};

const maybeAppendDecorators = R.map((filter = {}) => {
  const decorator = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'decorator'])(window);
  if (R.is(Function, decorator)) {
    return R.assoc('decorator', decorator, filter);
  } else {
    return filter;
  }
});

const appendFilterType = R.map((filter = {}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
});

const questionUuidKeyAsProp = (a, b) => R.assoc(b.questionUuid, b, a);

const determineTableColumnPage = () => {
  return R.cond([
    [R.test(/svr-responses/i), R.always('svrResponses')],
    [R.test(/reporting/i),     R.always('reporting')],
    [R.test(/saved-view/i),    R.always('svrResponses')],
    [R.test(/roll-ups/i),      R.always('savedRollup')],
    [R.T,                      R.always('svrResponses')]
  ])(window.location.pathname);
};

const ensureClientDoesNotSeeHiddenRecords = _isClientUser => n => {
  if (n.privacy === HIDDEN_FROM_CLIENT) {
    return !_isClientUser;
  } else {
    return true;
  }
};

const cacheImage = url => {
  if (!R.test(/(roll-ups)/gm, window.location.pathname)) {
    let small = document.createElement('img');
    let large = document.createElement('img');
    const src = document.getElementById('cached-images');

    small.src = makeDecoratedImageUrl(getRotation(url), 200)(url);
    large.src = makeDecoratedImageUrl(getRotation(url), 1280)(url);

    src.appendChild(small);
    src.appendChild(large);
  }
};

const canonicalKeyAsProp = (a, b) => R.assoc(b.canonicalKey, b, a);

const mediaGroupFromType = (doc) => {
  if (R.test(/image/, doc.type)) {
    return "photos";
  }
  if (R.test(/video/, doc.type)) {
    return "videos";
  }
};
const emptyMediaObject = { photos: [], videos: [] };
const mergeMedia = (k, l, r) => R.contains(k, ['photos', 'videos']) ? R.concat(R.defaultTo([], l), R.defaultTo([], r)) : r;

/**
 * Properties in JS use camelCaseNames
 * @param {String} str
 * @returns {String}
 */
const delimiterAndNextLetter = /_(.)/g;
const alphaNumeric           = /[a-z0-9]/gi;
const uppercaseAlphaNumeric  = R.compose(R.toUpper, R.head, R.match(alphaNumeric));
const transformFromColumn    = R.replace(delimiterAndNextLetter, uppercaseAlphaNumeric);

const parseValueForType = (type, value) => {
  if (R.isNil(value)) {
    return value;
  }
  return R.cond([
    [R.equals('number'), R.compose(d => parseInt(d, 10), R.always(value))],
    [R.equals('string'), R.compose(d => d.toString(), R.always(value))],
    [R.T, R.always(value)]
  ])(type);
 };

 const maybeSetProjectSegmentName  = (data) => {
  if (  R.type(data) === "Array")
  {
    return R.map(checkAndUpdateProjectSegmentName, data);
  }
  else
  {
    return checkAndUpdateProjectSegmentName(data);
  }
 };

const checkAndUpdateProjectSegmentName = (data) => {
  if (R.isEmpty(R.prop('metaProjectSegmentName', data))) {
     data = R.assoc('metaProjectSegmentName', R.prop('metaProjectName', data), data);
  }

  if (  !R.isNil( R.path(['metaJson','projectSegmentName'], data)) ) {
    if ( R.isEmpty(R.path(['metaJson','projectSegmentName'], data)) ){
      data = R.assocPath(['metaJson', 'projectSegmentName'], R.prop('metaProjectSegmentName', data) , data);
    }
   }

   return data;
 
};


const maybeSetReviewResubmittedStatus  = (data) => {
if (  R.type(data) === "Array")
{
  return R.map(checkAndUpdateReviewResubmittedStatus, data);
}
else
{
  return checkAndUpdateReviewResubmittedStatus(data);
}
};

const checkAndUpdateReviewResubmittedStatus = (data) => {
if (R.isEmpty(R.prop('metaLastRejectedDate', data)) ||  R.isNil( R.prop('metaLastRejectedDate', data)) ) {
  return data;
}
else{
  if ( R.equals(R.prop('metaReviewState', data), 1)) {
   
    let timediff = 0;
    if( !isNaN(Date.parse(R.prop('metaLastRejectedDate', data))) && !isNaN(Date.parse(R.prop('lastSubmissionDate', data))) )
    {
      const lastRejectedDateTime = moment(R.prop('metaLastRejectedDate', data));
      const lastSubmissionDateTime = moment(R.prop('lastSubmissionDate', data));
      timediff = lastSubmissionDateTime.diff(lastRejectedDateTime);
    }
    if ( timediff > 0 ){
      if (  R.isNil( R.path(['metaJson','reviewState'], data)) ) 
      {
        return R.assoc('metaReviewState', 5 , data);
      }
      else
      {
        let rdata =  R.assoc('metaReviewState', 5 , data);
        rdata = R.assocPath(['metaJson', 'reviewState'], 5 , rdata);
      
        return rdata;
      }
     
    }
    else{
      return data;
    }
  }
  else{
    return data;
  }
}
};

const getReviewStateColor = (workclassification) => (reviewState) => (timediffinhours) => {
  let reviewStateColor = '';
  let reviewStateColorSortValue = 0;
  let reviewStateSortValue = 99;

//  console.log("workclassification:", workclassification);
//  console.log("reviewState:", reviewState);
//  console.log("timediffinhours:", timediffinhours);
  switch (true) {
    case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 72):
     reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.RED;
     reviewStateColorSortValue = 1;
     reviewStateSortValue = 1;
     break;
    case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 48 && timediffinhours < 72):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.ORANGE;
      reviewStateColorSortValue = 2;
      reviewStateSortValue = 1;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 24 && timediffinhours < 48):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.YELLOW;
      reviewStateColorSortValue = 3;
      reviewStateSortValue = 1;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 0 && timediffinhours < 24):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
      reviewStateColorSortValue = 4;
      reviewStateSortValue = 1;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.PENDING_REVIEW && timediffinhours >= 72):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.RED;
      reviewStateColorSortValue = 1;
      reviewStateSortValue = 2;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.PENDING_REVIEW && timediffinhours >= 48 && timediffinhours < 72):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.ORANGE;
      reviewStateColorSortValue = 2;
      reviewStateSortValue = 2;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.PENDING_REVIEW && timediffinhours >= 24 && timediffinhours < 48):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.YELLOW;
      reviewStateColorSortValue = 3;
      reviewStateSortValue = 2;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.PENDING_REVIEW && timediffinhours >= 0 && timediffinhours < 24):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
      reviewStateColorSortValue = 4;
      reviewStateSortValue = 2;
      break;

      case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 72):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 1;
        reviewStateSortValue = 3;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 48 && timediffinhours < 72):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 2;
        reviewStateSortValue = 3;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 24 && timediffinhours < 48):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 3;
        reviewStateSortValue = 3;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.EMPLOYEE && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 0 && timediffinhours < 24):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 4;
        reviewStateSortValue = 3;
        break;
   
      
    case (workclassification === SVR_WORK_CLASSIFICATION.CONTRACTOR && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 72):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.RED;
      reviewStateColorSortValue = 1;
      reviewStateSortValue = 1;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.CONTRACTOR && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 48 && timediffinhours < 72):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.ORANGE;
      reviewStateColorSortValue = 2;
      reviewStateSortValue = 1;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.CONTRACTOR && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 24 && timediffinhours < 48):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.YELLOW;
      reviewStateColorSortValue = 3;
      reviewStateSortValue = 1;
      break;
    case (workclassification === SVR_WORK_CLASSIFICATION.CONTRACTOR && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 0 && timediffinhours < 24):
      reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
      reviewStateColorSortValue = 4;
      reviewStateSortValue = 1;
      break;      

      case (workclassification === SVR_WORK_CLASSIFICATION.CONTRACTOR && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 72):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 1;
        reviewStateSortValue = 3;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.CONTRACTOR && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 48 && timediffinhours < 72):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 2;
        reviewStateSortValue = 3;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.CONTRACTOR && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 24 && timediffinhours < 48):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 3;
        reviewStateSortValue = 3;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.CONTRACTOR && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 0 && timediffinhours < 24):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 4;
        reviewStateSortValue = 3;
        break;                        
  
      
      case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 72):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.RED;
        reviewStateColorSortValue = 1;
        reviewStateSortValue = 1;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 48 && timediffinhours < 72):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.ORANGE;
        reviewStateColorSortValue = 2;
        reviewStateSortValue = 1;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 24 && timediffinhours < 48):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.YELLOW;
        reviewStateColorSortValue = 3;
        reviewStateSortValue = 1;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.RESUBMITTED && timediffinhours >= 0 && timediffinhours < 24):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 4;
        reviewStateSortValue = 1;
        break;      
      case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.PENDING_REVIEW && timediffinhours >= 72):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.RED;
        reviewStateColorSortValue = 1;
        reviewStateSortValue = 2;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.PENDING_REVIEW && timediffinhours >= 48 && timediffinhours < 72):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.ORANGE;
        reviewStateColorSortValue = 2;
        reviewStateSortValue = 2;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.PENDING_REVIEW && timediffinhours >= 24 && timediffinhours < 48):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.YELLOW;
        reviewStateColorSortValue = 3;
        reviewStateSortValue = 2;
        break;
      case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.PENDING_REVIEW && timediffinhours >= 0 && timediffinhours < 24):
        reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
        reviewStateColorSortValue = 4;
        reviewStateSortValue = 2;
        break;                        

        case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 72):
          reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
          reviewStateColorSortValue = 1;
          reviewStateSortValue = 3;
          break;
        case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 48 && timediffinhours < 72):
          reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
          reviewStateColorSortValue = 2;
          reviewStateSortValue = 3;
          break;
        case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 24 && timediffinhours < 48):
          reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
          reviewStateColorSortValue = 3;
          reviewStateSortValue = 3;
          break;
        case (workclassification === SVR_WORK_CLASSIFICATION.DEFAULT && reviewState === SVR_RESPONSES_STATUSES.IN_REVIEW && timediffinhours >= 0 && timediffinhours < 24):
          reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
          reviewStateColorSortValue = 4;
          reviewStateSortValue = 3;
          break;                        
  
        default:
         reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
         reviewStateColorSortValue = 4;
         reviewStateSortValue = 99;
          break;
      }

      let  talentTypeSortValue = 99;
      switch (R.toUpper(workclassification)) {
        case R.toUpper(SVR_WORK_CLASSIFICATION.EMPLOYEE):
            talentTypeSortValue = 1;
          break;
          case R.toUpper(SVR_WORK_CLASSIFICATION.CONTRACTOR):
             talentTypeSortValue = 2;
          break;
          case R.toUpper(SVR_WORK_CLASSIFICATION.DEFAULT):
            talentTypeSortValue = 3;
         break;
        default:
            talentTypeSortValue = 99;
          break;
      }

     
    

  // console.log("reviewStateColor:", reviewStateColor);
  return {'SortColor':reviewStateColor, 'SortColorValue': reviewStateColorSortValue, 'reviewStateSortValue': reviewStateSortValue, 'TalentSortValue':talentTypeSortValue} ;
};


const maybeSetReviewStatusBackgroundTransparent  = (data) => {
  if (  R.type(data) === "Array")
  {
    return R.map(checkAndUpdateReviewStatusBackgroundTransparent, data);
  }
  else
  {
    return checkAndUpdateReviewStatusBackgroundTransparent(data);
  }
};

const checkAndUpdateReviewStatusBackgroundTransparent = (data) => {
  const reviewStateColor = SVR_RESPONSES_STATUSES_COLORS.TRANSPARENT;
  data = R.assoc('reviewStateColor', reviewStateColor , data);
  return data;
};

const maybeSetReviewStatusBackgroundColor  = (data) => {
  if (  R.type(data) === "Array")
  {
    return R.map(checkAndUpdateReviewStatusBackgroundColor, data);
  }
  else
  {
    return checkAndUpdateReviewStatusBackgroundColor(data);
  }
};


const checkAndUpdateReviewStatusBackgroundColor = (data) => {
  if (   R.isNil( R.prop('metaTalentType', data)) ) 
  {
    data = R.assoc('metaTalentType', SVR_WORK_CLASSIFICATION.DEFAULT, data);
  }

  if(  !isNaN(Date.parse(R.prop('lastSubmissionDate', data))) )
  {
    const currentDateTime = moment().utc().format();
    const lastSubmissionDateTime = moment(R.prop('lastSubmissionDate', data));
    const timediff = moment(currentDateTime).diff(lastSubmissionDateTime);
    const timediffinhours = Math.floor(timediff / (3600 * 1000) );
    const workclassification = R.prop('metaTalentType', data); 
    const reviewState = R.prop('metaReviewState', data);

    if ( window.localStorage.getItem("displaycolors") === 'YES' )
   {
      const reviewStateColorSort =  getReviewStateColor(workclassification)(reviewState)(timediffinhours);
    //  console.log('reviewStateColor:', reviewStateColor);
    data = R.assoc('reviewStateColor', reviewStateColorSort.SortColor , data);
    data = R.assoc('talentTypeSortValue',reviewStateColorSort.TalentSortValue , data );
    data = R.assoc('reviewStateSortValue',reviewStateColorSort.reviewStateSortValue , data );
    data = R.assoc('reviewStateColorSortValue',reviewStateColorSort.SortColorValue , data );
    data = R.assoc('reviewWaitTime', timediff, data);
   }

    return data;
  }
  else {
    return data;
  }
};


const maybySetReviewPreDefinedSortingFiltering = (data) => {
  const applyPreDefinedSort = R.sortWith([
    R.ascend(R.prop('talentTypeSortValue')),
    R.ascend(R.prop('reviewStateSortValue')),
    R.ascend(R.prop('reviewStateColorSortValue')),
    R.descend(R.prop('reviewWaitTime'))
  ]);
  const filterReviewStatus = x => (R.equals(R.prop('metaReviewState', x) ,1) || (R.equals(R.prop('metaReviewState', x) ,2)) || R.equals(R.prop('metaReviewState', x) ,5));

  if ( window.localStorage.getItem("displaycolors") === 'YES' )
  {
    return R.compose(
      applyPreDefinedSort,
      R.filter(filterReviewStatus)
    )(data);
  }
  else{
    return data;
  }

};

const SvrResponsesReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case `${FETCH_ALL}_SUCCESS`: {  
      const tableColumns = getTableColumnsForPage('storeVisit', determineTableColumnPage());
      const wnamespaces = R.path(['meta', 'previousAction', 'payload', 'namespace'], action);
      if ( R.is(Array, wnamespaces) ) {
      var returnsvrdata = {};
      var currentindex = 0;
      var composedtempSvrWidgetData = {};
      var firstwidgetid = "";
      wnamespaces.forEach(
        (eachnamespace) => {
            if ( currentindex === 0) {
              firstwidgetid = eachnamespace;
              composedtempSvrWidgetData =  R.compose(
                                                      R.assocPath([firstwidgetid], R.__, initialState ),
                                                      R.assoc('tableColumns', tableColumns),
                                                      data => ({ data, transformedData: {} }),
                                                        R.indexBy(R.prop('eventId')),
                                                        R.pathOr([], ['payload', 'data', 'data']),
                                                      )(action) ;
            
              
              if (R.isEmpty(returnsvrdata)){
                returnsvrdata =  composedtempSvrWidgetData ;
              }
            }
            else {
                  returnsvrdata[eachnamespace] =  composedtempSvrWidgetData[firstwidgetid];
              }
              currentindex = currentindex + 1;
            }
          );

          const combinesvrresponsepros =  (x, y) =>  R.merge( x, y);
          const returnupdatedsvrdata = x => R.compose(
                                        (eachitem) =>  combinesvrresponsepros(x, eachitem)  
                                )(state);
          const retData = returnupdatedsvrdata(returnsvrdata);
          return retData;   
          
        }
      else{
        return R.compose(
              R.assocPath([R.path(['meta', 'previousAction', 'payload', 'namespace'])(action)], R.__, state),
              R.assoc('tableColumns', tableColumns),
              data => ({ data, transformedData: R.clone(data) }),
              R.indexBy(R.prop('eventId')),
              maybeSetReviewStatusBackgroundColor,
              maybeSetReviewStatusBackgroundTransparent,
              maybeSetReviewResubmittedStatus,
              maybeSetProjectSegmentName,
              R.pathOr([], ['payload', 'data', 'data']),
            )(action);
      }
    }

    
    // case `${FETCH_ALL}_SUCCESS`: {
    //   const tableColumns = getTableColumnsForPage('storeVisit', determineTableColumnPage());
    //   return R.compose(
    //     R.assocPath([R.path(['meta', 'previousAction', 'payload', 'namespace'])(action)], R.__, state),
    //     R.assoc('tableColumns', tableColumns),
    //     data => ({ data, transformedData: R.clone(data) }),
    //     R.indexBy(R.prop('eventId')),
    //     R.pathOr([], ['payload', 'data', 'data']),
    //   )(action);
    // }

    case `${FETCH}_SUCCESS`: {
      const rdata  = R.pathOr({}, ['payload', 'data', 'data'])(action);
      const sdata = maybeSetProjectSegmentName(rdata);
      const data = maybeSetReviewResubmittedStatus(sdata);
      
      const  namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);
      const _isClientUser = isClientUser();
      if (ensureClientDoesNotSeeHiddenRecords(_isClientUser)(data)) {
        return R.compose(
          R.assocPath([namespace], R.__, state),
          R.assoc('transformedData', [R.clone(data)]),
          R.assocPath(['data', data.eventId + ''], data),
        )({});
      } else {
        return R.assocPath([namespace, 'data', data.eventId + ''], {}, state);
      }
    }

    case `${FETCH_FORM}_SUCCESS`: {
      const data  = R.pathOr([], ['payload', 'data', 'data'])(action),
        namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      return R.compose(
        R.assocPath([namespace, 'forms'], R.__, state),
        R.reduce(canonicalKeyAsProp, {}),
      )([data]);
    }
    case `${SET_QUESTIONS}`: {
      return R.assocPath([action.payload.namespace, 'questions'], action.payload.data, state);
    }
    case `${FETCH_PHOTO_QUESTIONS}_SUCCESS`: {
      const data  = R.pathOr([], ['payload', 'data', 'data'])(action),
        namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      const photoQuestions = R.compose(
        R.filter(R.either(R.propEq('type', 'photo-input'), R.propEq('type', 'signature-input'))),
        R.flatten,
        R.map(recursivelyPluck('content')),
        R.flatten,
        R.map(R.path(['content', 'structure']))
      )(data);

      const storeVisitIds = R.compose(
        R.map(R.partialRight(parseInt, [10])),
        R.pathOr([], ['meta', 'previousAction', 'payload', 'storeVisitIds'])
      )(action);

      const formAnswers = R.compose(
        R.indexBy(R.prop('questionUuid')),
        R.path(['meta', 'previousAction', 'payload', 'formAnswers'])
      )(action);

      const photoAnswers = R.compose(
        R.filter(R.identity),
        R.map(q => formAnswers[q.uuid])
      )(photoQuestions);

      const associateAllPhotoAnswers = state => R.compose(
        R.mergeAll,
        R.map(id => R.assocPath([namespace, 'photoAnswers', `${id}`], photoAnswers.filter(a => a.storeVisitId === id))(state)),
      )(storeVisitIds);

      if (storeVisitIds) {
        return R.compose(
          _state => photoAnswers.length ? associateAllPhotoAnswers(_state) : _state,
          R.assocPath([namespace, 'photoQuestions'], photoQuestions) // TODO refactor to use same path as answers
        )(state);
      } else {
        return state;
      }
    }

    case `${FETCH_VIDEO_QUESTIONS}_SUCCESS`: {
      const data  = R.pathOr([], ['payload', 'data', 'data'])(action),
        namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      const videoQuestions = R.compose(
        R.filter(R.propEq('type', 'video-input')),
        R.flatten,
        R.map(recursivelyPluck('content')),
        R.flatten,
        R.map(R.path(['content', 'structure']))
      )(data);

      const storeVisitIds = R.compose(
        R.map(R.partialRight(parseInt, [10])),
        R.pathOr([], ['meta', 'previousAction', 'payload', 'storeVisitIds'])
      )(action);

      const formAnswers = R.compose(
        R.indexBy(R.prop('questionUuid')),
        R.path(['meta', 'previousAction', 'payload', 'formAnswers'])
      )(action);

      const videoAnswers = R.compose(
        R.filter(R.identity),
        R.map(q => formAnswers[q.uuid])
      )(videoQuestions);

      const associateAllVideoAnswers = state => R.compose(
        R.mergeAll,
        R.map(id => R.assocPath([namespace, 'videoAnswers', `${id}`], videoAnswers.filter(a => a.storeVisitId === id))(state)),
      )(storeVisitIds);

      if (storeVisitIds) {
        return R.compose(
          _state => videoAnswers.length ? associateAllVideoAnswers(_state) : _state,
          R.assocPath([namespace, 'videoQuestions'], videoQuestions) // TODO refactor to use same path as answers
        )(state);
      } else {
        return state;
      }
    }

    case `${FETCH_MEDIA_QUESTIONS}_SUCCESS`: {
      const data  = R.pathOr([], ['payload', 'data', 'data'])(action),
        namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      const mediaQuestions = R.compose(
        R.groupBy(R.prop('type')),
        R.filter(R.anyPass([R.propEq('type', 'signature-input'), R.propEq('type', 'photo-input'), R.propEq('type', 'video-input')])),
        R.flatten,
        R.map(recursivelyPluck('content')),
        R.flatten,
        R.map(R.path(['content', 'structure']))
      )(data);

      const storeVisitIds = R.compose(
        R.map(R.partialRight(parseInt, [10])),
        R.pathOr([], ['meta', 'previousAction', 'payload', 'storeVisitIds'])
      )(action);

      const formAnswers = R.compose(
        R.indexBy(R.prop('questionUuid')),
        R.path(['meta', 'previousAction', 'payload', 'formAnswers'])
      )(action);

      const mediaAnswers = R.compose(
        R.map(R.filter(R.identity)),
        R.map(R.map(q => formAnswers[q.uuid]))
      )(mediaQuestions);

      const mediaAnswersCount = R.compose(
        R.length,
        R.flatten,
        R.values
      )(mediaAnswers);

      const associateAllMediaAnswers = state => R.compose(
        R.mergeAll,
        R.map(id =>
          R.compose(
            R.assocPath(
              [namespace, 'videoAnswers', `${id}`],
              R.compose(R.filter(R.propEq('storeVisitId', id)), R.propOr([], 'video-input'))(mediaAnswers)
            ),
            R.assocPath(
              [namespace, 'photoAnswers', `${id}`],
              R.compose(
                R.filter(
                  R.propEq('storeVisitId', id)
                ),
                R.propOr([], 'photo-input')
              )(mediaAnswers)
            ),
            R.assocPath(
              [namespace, 'signatureAnswers', `${id}`],
              R.compose(
                R.filter(
                  R.propEq('storeVisitId', id)
                ),
                R.propOr([], 'signature-input')
              )(mediaAnswers)
            ),
          )(state)),
      )(storeVisitIds);

      if (storeVisitIds) {
        return R.compose(
          _state => mediaAnswersCount ? associateAllMediaAnswers(_state) : _state,
          R.assocPath([namespace, 'photoQuestions'], R.propOr([], 'photo-input', mediaQuestions)),
          R.assocPath([namespace, 'signatureQuestions'], R.propOr([], 'signature-input', mediaQuestions)),
          R.assocPath([namespace, 'videoQuestions'], R.propOr([], 'video-input', mediaQuestions)) // TODO refactor to use same path as answers
        )(state);
      } else {
        return state;
      }
    }

    case `${UPDATE_DOCUMENT}_SUCCESS`: {
      const doc   = R.pathOr({}, ['payload', 'data', 'data'], action),
        namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      if (R.test(/image/, doc.type)) {
        cacheImage(doc.url);
        if (!(R.isNil(doc.xsurl) && R.isEmpty(doc.xsurl))) {
          if (R.length(doc.xsurl) > 0) {
            cacheImage(doc.xsurl);
          }
        }
      }

      const medyaType = R.test(/image/, doc.type) ? 'photos' : (R.test(/video/, doc.type) ? 'videos' : null);

      return R.assocPath([namespace, medyaType], R.compose(
        R.append(doc),
        R.reject(R.propEq('id', doc.id)),
        R.pathOr([], [namespace, medyaType])
      )(state), state);
    }

    case `${FETCH_DOCUMENTS}_SUCCESS`: {
      const data  = R.pathOr({}, ['payload', 'data', 'data'])(action),
        namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      R.forEach(doc => {
        if (R.test(/image/, doc.type)) {
          cacheImage(doc.url);
        }
      })(data);

      return R.mergeDeepLeft(R.compose(
        R.objOf(namespace),
        R.map(R.reject(R.isEmpty)),
        R.map(R.uniqBy(R.prop('uuid'))),
        R.mergeDeepWithKey(mergeMedia, R.groupBy(mediaGroupFromType, data)),
        R.mergeDeepWithKey(mergeMedia, emptyMediaObject),
        R.pick(['photos', 'videos']),
        R.propOr({}, [namespace])
      )(state), state);
    }

    case `${FETCH_DOCUMENTS}_ERROR`: {
      const svrResponse = R.pathOr({}, ['payload', 'error'])(action);
      return R.merge(['media/error', svrResponse], state);
    }

    case `${RESET_PHOTOS_REDUCER}`: {
      return R.compose(
        R.assocPath([action.payload.namespace, 'photos'], []),
        R.assocPath([action.payload.namespace, 'photoQuestions'], [])
      )(state);
    }

    case `${RESET_VIDEO_REDUCER}`: {
      return R.compose(
        R.assocPath([action.payload.namespace, 'videos'], []),
        R.assocPath([action.payload.namespace, 'videoQuestions'], [])
      )(state);
    }

    case `${RESET_MEDIA_REDUCER}`: {
      return R.compose(
        R.assocPath([action.payload.namespace, 'photos'], []),
        R.assocPath([action.payload.namespace, 'photoQuestions'], []),
        R.assocPath([action.payload.namespace, 'videos'], []),
        R.assocPath([action.payload.namespace, 'videoQuestions'], [])
      )(state);
    }

    case `${UPDATE}_SUCCESS`: {
      const modifySvrResponse = R.pathOr({}, ['payload', 'data', 'data'], action);
      const namespace         = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      return R.assocPath([namespace, 'data', modifySvrResponse.eventId + ''], modifySvrResponse, state);
    }
    case `${RESPONSEREJECT}_SUCCESS`: {
      const modifySvrResponse = R.pathOr({}, ['payload', 'data', 'data'], action);      
      const namespace         = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);     
      return R.assocPath([namespace, 'data', modifySvrResponse.eventId + ''], modifySvrResponse, state);
    }
    case `${DELETE}_SUCCESS`: {
      const storeVisitId = R.path(['meta', 'previousAction', 'payload', 'storeVisitId'])(action);
      const eventId = R.compose(R.prop('eventId'), R.find(R.propEq('id', storeVisitId)), R.prop('transformedData'))(state);

      return R.compose(
        R.dissocPath(['data', `${eventId}`]),
        R.over(R.lensProp('transformedData'), R.reject(R.propEq('id', storeVisitId))),
      )(state);
    }

    case `${CREATE_FORM_ANSWER}_SUCCESS`:
    case `${UPDATE_FORM_ANSWER}_SUCCESS`: {
      const modifyFormAnswer = R.pathOr({}, ['payload', 'data', 'data'], action);
      const namespace        = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      return R.assocPath([namespace, 'formAnswers', modifyFormAnswer.questionUuid], modifyFormAnswer, state);
    }

    case `${FETCH_FORM_ANSWERS}_SUCCESS`: {
      const data  = R.pathOr({}, ['payload', 'data', 'data'])(action),
        namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      const formAnswers     = R.reduce(questionUuidKeyAsProp, {})(data);
      const questionAnswers = R.groupBy(R.prop('storeVisitId'))(data);

      return R.compose(
        R.assocPath([namespace, 'questionAnswers'], questionAnswers),
        R.assocPath([namespace, 'formAnswers'], formAnswers)
      )(state);

    }

    case FILTER_BY_ACTION: {

      const filterList = R.compose(
        maybeAppendDecorators,
        appendFilterType,
        R.defaultTo({})
      )(action.payload.filters);

      const filterListNeedsUpdating = R.compose(
        R.not,
        R.both(R.complement(R.isEmpty), R.equals(filterList)),
        R.pathOr({}, [action.payload.namespace, 'filterList'])
      )(state);

      if (filterListNeedsUpdating) {

        const _state = R.assocPath([action.payload.namespace, 'filterList'], filterList, state);

        if (!R.isEmpty(filterList)) {
          try {
            window.localStorage.setItem(`persistedFilters:${action.payload.namespace}`, JSON.stringify(filterList));
          } catch (e) {
            return _state;
          }
        } else {
          try {
            window.localStorage.removeItem(`persistedFilters:${action.payload.namespace}`);
          } catch (e) {
            return _state;
          }
        }

        return _state;
      } else {
        return state;
      }

    }

    case TRANSFORM_DATA: {

      const emptyData = R.cond([
        [R.equals('string'), R.always('')],
        [R.equals('number'), R.always(0)],
        [R.equals('date'),   R.equals(new Date(-8640000000000000))],
        [R.T,                R.always('')]
      ])(action.payload.sortType);
      const tableColumns = getTableColumnsForPage('storeVisit', determineTableColumnPage());

      const _isClientUser = isClientUser();

      const compareValueIfFilterHasSelections = props => {
        const path    = props[0],
          data        = props[1],
          decorator   = props[2],
          type        = props[3],
          exclude     = props[4],
          parsedData  = type === 'number' ? R.map(d => parseInt(d, 10))(data) : data;

        const filterableValue = v => {
          if (RA.hasPath(path, v)) {
            return parseValueForType(type, maybeDecorateColumn(decorator)(R.path(path, v)));
          }

          const column = R.compose(transformFromColumn, R.prop('column'), R.find(R.propEq('path', path)))(action.payload.tableColumns);
          return parseValueForType(type, maybeDecorateColumn(decorator)(R.prop(column, v)));
        };

        const isWithinDateRange = R.anyPass(
          R.map(range => date => moment(date, 'MM/DD/YYYY hh:mm:A').isBetween(moment(range[0], 'YYYY-MM-DD'), moment(range[1], 'YYYY-MM-DD'), 'day', '[]'))(data)
        );

        const finder = type === 'date' ? isWithinDateRange : R.either(R.contains(R.__, parsedData), R.contains(R.__, data));
        const filter = exclude 
          ? R.complement(R.compose(finder, filterableValue)) 
          : R.compose(finder, filterableValue);

        return parsedData.length ? filter : R.T;
      };

      const maybeStringToNumber = value => {
        if (action.payload.sortType === 'number' && R.is(String, value)) {
          return parseInt(value, 10);
        }

        return value;
      };
      const maybeLowerCaseString = R.when(R.is(String), R.toLower);
      const ensureEmptyTypeValue = R.unless(R.identity, R.always(emptyData));

      const allFilters = R.compose(
        R.map(compareValueIfFilterHasSelections),
        R.values,
        R.map(R.props(['path', 'data', 'decorator', 'type', 'exclude'])),
      )(action.payload.filterList);

      const transformedData = R.compose(
        R.sort(
          R[`${action.payload.order}end`](
            R.compose(
              maybeStringToNumber,
              ensureEmptyTypeValue,
              maybeLowerCaseString,
              R.ifElse(
                RA.hasPath(action.payload.orderBy.split('.')),
                R.path(action.payload.orderBy.split('.')),
                data => R.compose(R.prop(R.__, data), transformFromColumn, R.prop('column'), R.find(R.propEq('pathStr', action.payload.orderBy)))(tableColumns)
              )
            )
          )
        ),
        R.filter(R.both(
          R.allPass(allFilters),
          ensureClientDoesNotSeeHiddenRecords(_isClientUser)
        ))
      )(action.payload.data);

      const transformedDataWithSortAndColors = maybySetReviewPreDefinedSortingFiltering(transformedData);

      return R.assocPath([action.payload.namespace, 'transformedData'], transformedDataWithSortAndColors)(state);
    }

    case SHOW_SEARCH_FILTER: {
      return { ...state, showSearchFilter: action.payload };
    }

    case SELECT_NAVIGATE_FILTER: {
      return { ...state, navigateFilter: action.payload };
    }

    case CLEAR_CURRENT_DATA: {
      return R.assocPath([action.payload.namespace, 'data'], [], state);
    }

    default: {
      return state;
    }
  }
};

export const getSvrResponses = (state, namespace = STATE_KEY) => R.values(
  R.pathOr([], [STATE_KEY, namespace, 'data'], state)
) || [];

export const getTransformedData = (state, namespace = STATE_KEY) => R.values(
  R.pathOr([], [STATE_KEY, namespace, 'transformedData'], state)
) || [];

export const getSvrResponse = (state, namespace, id) => {
  return R.compose(
    R.defaultTo({}),
    R.find(R.propEq('id', parseInt(id))),
    R.values,
    R.pathOr({}, [STATE_KEY, namespace, 'data'])
  )(state);
};

export const getTableColumns        = namespace => R.pathOr(getTableColumnsForPage('storeVisit', determineTableColumnPage()), [STATE_KEY, namespace, 'tableColumns']);
export const getFormAnswers         = namespace => R.pathOr({}, [STATE_KEY, namespace, 'formAnswers']);
export const getFilterList          = namespace => R.pathOr({}, [STATE_KEY, namespace, 'filterList']);
export const getPhotos              = namespace => R.pathOr([], [STATE_KEY, namespace, 'photos']);
export const getPhotoQuestions      = namespace => R.pathOr([], [STATE_KEY, namespace, 'photoQuestions']);
export const getPhotoAnswers        = namespace => storeVisitId => R.pathOr([], [STATE_KEY, namespace, 'photoAnswers', storeVisitId]);
export const getVideos              = namespace => R.pathOr([], [STATE_KEY, namespace, 'videos']);
export const getVideoQuestions      = namespace => R.pathOr([], [STATE_KEY, namespace, 'videoQuestions']);
export const getVideoAnswers        = namespace => storeVisitId => R.pathOr([], [STATE_KEY, namespace, 'videoAnswers', storeVisitId]);
export const getSignatureQuestions  = namespace => R.pathOr([], [STATE_KEY, namespace, 'signatureQuestions']);
export const getSignatureAnswers    = namespace => storeVisitId => R.pathOr([], [STATE_KEY, namespace, 'signatureAnswers', storeVisitId]);
export const getShowSearchFilter    = R.pathOr(false, [STATE_KEY, 'showSearchFilter']);
export const getNavigateFilter      = R.pathOr(null, [STATE_KEY, 'navigateFilter']);


export const getQuestions = (state, namespace = STATE_KEY) => {
  const questions = R.pathOr([], [STATE_KEY, namespace, 'questions'], state),
    forms         = R.values(R.path([STATE_KEY, namespace, 'forms'], state)),
    questionIdentifier = 'uuid';

  return getFlattenedQuestions(questions, forms, isClientUser(), questionIdentifier);
};

export const getQuestionAnswers = (state, namespace = STATE_KEY) => R.pathOr({}, [STATE_KEY, namespace, 'questionAnswers'], state);

export const getSvrPhotos = storeVisitId => (state, namespace = STATE_KEY) => {
  const maybeAnswerValue = R.propOr('', 'answerValue');
  const photos           = R.indexBy(R.prop('uuid'))(getPhotos(namespace)(state));

  return R.compose(
    R.filter(R.identity),
    R.pluck('document'),
    R.map(q => R.assoc('document', photos[maybeAnswerValue(q)])(q)),
    getPhotoAnswers(namespace)(storeVisitId)
  )(state);
};

export const getSvrSignatures = storeVisitId => (state, namespace = STATE_KEY) => {
  const maybeAnswerValue = R.propOr('', 'answerValue');
  const photos           = R.indexBy(R.prop('uuid'))(getPhotos(namespace)(state));

  return R.compose(
    R.filter(R.identity),
    R.pluck('document'),
    R.map(q => R.assoc('document', photos[maybeAnswerValue(q)])(q)),
    getSignatureAnswers(namespace)(storeVisitId)
  )(state);
};

export const getSvrVideos = storeVisitId => (state, namespace = STATE_KEY) => {
  const maybeAnswerValue = R.propOr('', 'answerValue');
  const videos           = R.indexBy(R.prop('uuid'))(getVideos(namespace)(state));

  return R.compose(
    R.filter(R.identity),
    R.pluck('document'),
    R.map(q => R.assoc('document', videos[maybeAnswerValue(q)])(q)),
    getVideoAnswers(namespace)(storeVisitId)
  )(state);
};

export default SvrResponsesReducer;
